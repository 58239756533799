import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as FiIcons from 'react-icons/fi';

export const ProfilebarData = [
  {
    title: '@Fulano',
    path: '/',
    icon: '',
    cName: 'nav-text'
  },
  {
    title: 'Minhas descrições',
    path: '/drafts',
    icon: <FaIcons.FaEdit />,
    cName: 'nav-text'
  },
  {
    title: 'Meus Rascunhos',
    path: '/drafts',
    icon: <FaIcons.FaEdit />,
    cName: 'nav-text'
  },
  {
    title: 'Preferências',
    path: '/preferences',
    icon: <FaIcons.FaEdit />,
    cName: 'nav-text'
  },
  {
    title: 'Logout',
    path: '/logout',
    icon: <FiIcons.FiLogOut />,
    cName: 'nav-text'
  }
];