import { React, useContext, useState, useEffect, useMemo } from 'react';
import { Space, Select, TreeSelect, DatePicker, Button, Drawer, Affix, Collapse, Input } from 'antd'
import { LoadingOutlined, SearchOutlined, FilterOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';

import { ReactComponent as StackMenu } from "../menu_stack_icon.svg";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Search } = Input;

const { Panel } = Collapse;

const { Option } = Select;
const { RangePicker } = DatePicker;


function DescriptionsDataFilter({ setDescriptionsDataFiltered, descriptionFieldsData, descriptionsData, descriptionFieldsDataLoaded }) {
    const urlDescription = 'geologicalContext';
    const urlBasinDescription = 'watershed';
    const urlGeochronologyDescription = 'geochronology';
    const urlSedimentaryEnvironmentDescription = 'sedimentaryEnvironment'
    const urlDepositionalSystem = 'depositionalSystem'

    const [descriptionTypeFilter, setDescriptionTypeFilter] = useState({ "value": [], "label": [] });
    const [basinsFilter, setBasinsFilter] = useState({ "value": [], "label": [] });
    const [geochronologyFilter, setGeochronologyFilter] = useState({ "value": [], "label": [] });
    const [sedimentaryEnvironmentFilter, setSedimentaryEnvironmentFilter] = useState({ "value": [], "label": [] });
    const [depositionalSystemFilter, setDepositionalSystemFilter] = useState({ "value": [], "label": [] });
    const [dataDateMinFilter, setDataDateMinFilter] = useState(null);
    const [dataDateMaxFilter, setDataDateMaxFilter] = useState(null);
    const [searchFilter, setSearchFilter] = useState("")

    const [openFilters, setOpenFilters] = useState(false);

    const dateFormat = 'DD/MM/YYYY';

    const descriptionTypeOptions = [
        {
            label: "Afloramento",
            value: "Afloramento"
        },
        {
            label: "Subsuperfície",
            value: "Subsuperfície"
        },
        {
            label: "Sistema Recente",
            value: "Sistema Recente"
        },
        {
            label: "Modelo",
            value: "Modelo"
        },
    ];

    const descriptionFields = useMemo(() => {
        let parsedDescriptionFields = {};

        const generateList = (data) => {
            let tempList = [];
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                let newNode = {}
                newNode.title = node.labels.pt[0]
                newNode.value = node.iri;
                if (node.types)
                    newNode.children = generateList(node.types)
                else
                    newNode.children = [];
                tempList.push(newNode);
            }
            return tempList;
        };

        if (descriptionFieldsData) {
            let basinsData = descriptionFieldsData[urlDescription][urlBasinDescription].types;
            parsedDescriptionFields.basins = basinsData;

            let geochronologyData = descriptionFieldsData[urlDescription][urlGeochronologyDescription].types;
            parsedDescriptionFields.geochronology = generateList(geochronologyData);

            let sedimentaryEnvironmentData = descriptionFieldsData[urlDescription][urlSedimentaryEnvironmentDescription].types;
            parsedDescriptionFields.sedimentaryEnvironment = generateList(sedimentaryEnvironmentData);

            let depositionalSystemData = descriptionFieldsData[urlDescription][urlDepositionalSystem].types;
            parsedDescriptionFields.depositionalSystem = generateList(depositionalSystemData);

            return parsedDescriptionFields;
        }
    }, [descriptionFieldsData]);


    const descriptionTypeChange = (value) => {
        setDescriptionTypeFilter({ "value": value, "label": value });
    };

    const basinsChange = (value, label) => {
        setBasinsFilter({ "value": value, "label": label.map(lst => lst["title"]) });
    };

    const geochronologyChange = (value, label) => {

        setGeochronologyFilter({ "value": value, "label": label });
    };

    const sedimentaryEnvironmentChange = (value, label) => {
        console.log(descriptionFields);
        setSedimentaryEnvironmentFilter({ "value": value, "label": label });
    };

    const depositionalSystemChange = (value, label) => {
        setDepositionalSystemFilter({ "value": value, "label": label });
    };

    const setSearchFilterChange = (value, _e, info) => {
        setSearchFilter([value]);
    };
    const dataDateChange = (value) => {
        if (value == null) {
            setDataDateMinFilter(null);
            setDataDateMaxFilter(null);
        }
        else {
            let min = value[0].format("YYYY-MM-DD");
            let max = value[1].format("YYYY-MM-DD");

            setDataDateMinFilter(min);
            setDataDateMaxFilter(max);
        }

    };

    const showDrawerFilters = () => {
        setOpenFilters(true);
    };

    const onCloseFilters = () => {
        setOpenFilters(false);
    };

    useEffect(() => {
        const filterDataByType = (dataList, attribute, targetTypes) => {
            return dataList.filter((data) => targetTypes.includes(data.geoDescriptionStored.descriptionInformation[attribute]));
        };

        const filterDataListByType = (dataList, attribute, targetTypes) => {
            // Create a Set from the first array
            return dataList.filter((data) => data.geoDescriptionStored.descriptionInformation[attribute].some(value => targetTypes.includes(value)));
        };

        const filterDate = (dataList, attribute, dateMin, dateMax) => {
            return dataList.filter((data) => new Date(dateMin) <= new Date(data.geoDescriptionStored.descriptionInformation[attribute]) && new Date(data.geoDescriptionStored.descriptionInformation[attribute]) <= new Date(dateMax));
        };

        if (descriptionsData) {
            let filteredData = descriptionsData

            if (descriptionTypeFilter["value"].length !== 0) {
                filteredData = filterDataByType(filteredData, "descriptionType", descriptionTypeFilter["value"]);
            }

            if (basinsFilter["value"].length !== 0) {
                filteredData = filterDataByType(filteredData, "watershed", basinsFilter["value"]);
            }

            if (geochronologyFilter["value"].length !== 0) {
                filteredData = filterDataListByType(filteredData, "geochronology", geochronologyFilter["value"]);
            }

            if (sedimentaryEnvironmentFilter["value"].length !== 0) {
                filteredData = filterDataListByType(filteredData, "sedimentaryEnvironment", sedimentaryEnvironmentFilter["value"]);
            }

            if (depositionalSystemFilter["value"].length !== 0) {
                filteredData = filterDataListByType(filteredData, "depositionalSystem", depositionalSystemFilter["value"]);
            }

            if (dataDateMinFilter !== null && dataDateMaxFilter !== null) {
                filteredData = filterDate(filteredData, "dataDate", dataDateMinFilter, dataDateMaxFilter);
            }

            setDescriptionsDataFiltered(filteredData);
        }

    }, [descriptionTypeFilter, basinsFilter, geochronologyFilter, sedimentaryEnvironmentFilter, depositionalSystemFilter, dataDateMinFilter, dataDateMaxFilter])

    const genExtra = (icon, text, description = ["texto", "outro texto"]) => (
        <span>
            <strong> {icon} {text} </strong>
            <br />
            {description["label"] ? (
                <span>{description["label"].join(', ')}</span>
            ) : (
                <span />
            )}
        </span>
    );

    const genExtraText = (icon, text, description) => (
        <span>
            <strong> {icon} {text} </strong>
            <br />
            {description !== "" ? (
                <span>Buscando por <i>{description}</i></span>
            ) : (
                <span />
            )}
        </span>
    );

    const genExtraDate = (icon, text, min, max) => (
        <span>
            <strong> {icon} {text} </strong>
            <br />
            {min ? (
                <span>De {new Date(min).toLocaleDateString("pt-BR")} até {new Date(max).toLocaleDateString("pt-BR")}</span>
            ) : (
                <span />
            )
            }
        </span>
    );

    let filterMenuIcon = <Icon component={StackMenu} style={{ display: 'inline-flex', fontSize: '18px' }} />
    let filterIcon = <FilterOutlined style={{ display: 'inline-flex' }} />
    let searchIcon = <SearchOutlined style={{ display: 'inline-flex' }} />

    return (
        <>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>

                <div style={{ position: 'fixed', top: '10%', right: 20 }}>
                    <Button size={"large"} onClick={showDrawerFilters} >{filterMenuIcon}</Button>
                </div>

                <Drawer
                    title={"Pesquisa e Filtros"}
                    placement="right"
                    width={320}
                    onClose={onCloseFilters}
                    open={openFilters}
                    style={{ zIndex: '1002' }}
                >

                    <Collapse>
                        <Panel header={genExtraText(searchIcon, "Busca descrição", searchFilter)} key="0" showArrow={false} style={{ background: 'white' }}>
                            <Search placeholder="Busca por descrições" enterButton onSearch={setSearchFilterChange} autoSize />
                        </Panel>
                    </Collapse>
                    <br />

                    <Collapse>
                        <Panel header={genExtra(filterIcon, "Tipo", descriptionTypeFilter)} key="0" showArrow={false} style={{ background: 'white' }}>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Please select"
                                onChange={descriptionTypeChange}
                                options={descriptionTypeOptions}
                            />
                        </Panel>
                    </Collapse>
                    <br />

                    <Collapse>
                        <Panel header={genExtra(filterIcon, "Bacias", basinsFilter)} key="0" showArrow={false} style={{ background: 'white' }}>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Please select"
                                onChange={basinsChange}
                            >
                                {!descriptionFieldsDataLoaded ? (
                                    <Option disabled key="loading" value="loading">Carregando...</Option>
                                ) : (
                                    Object.entries(descriptionFields.basins)?.map(([iri, basin]) => {
                                        return <Option key={iri} value={iri} title={basin.labels.pt[0]}>
                                            {basin.labels.pt[0]}
                                        </Option>
                                    })
                                )}
                            </Select>
                        </Panel>
                    </Collapse>
                    <br />

                    <Collapse>
                        <Panel header={genExtra(filterIcon, "Geocronologia", geochronologyFilter)} key="0" showArrow={false} style={{ background: 'white' }}>
                            <TreeSelect
                                style={{ width: '100%' }}
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                }}
                                treeData={!descriptionFieldsDataLoaded ? (
                                    [{ value: "Carregando", label: "Carregando...", children: [] }]
                                ) : (
                                    descriptionFields.geochronology
                                )}
                                multiple
                                treeLine={{ showLeafIcon: false }}
                                showArrow
                                onChange={geochronologyChange}
                            />
                        </Panel>
                    </Collapse>
                    <br />

                    <Collapse>
                        <Panel header={genExtra(filterIcon, "Ambiente de Sedimentação", sedimentaryEnvironmentFilter)} key="0" showArrow={false} style={{ background: 'white' }}>
                            <TreeSelect
                                style={{ width: '100%' }}
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                }}
                                treeData={!descriptionFieldsDataLoaded ? (
                                    [{ value: "Carregando", label: "Carregando...", children: [] }]
                                ) : (
                                    descriptionFields.sedimentaryEnvironment
                                )}
                                multiple
                                treeLine={{ showLeafIcon: false }}
                                showArrow
                                onChange={sedimentaryEnvironmentChange}
                            />
                        </Panel>
                    </Collapse>
                    <br />

                    <Collapse>
                        <Panel header={genExtra(filterIcon, "Sistema Deposicional", depositionalSystemFilter)} key="0" showArrow={false} style={{ background: 'white' }}>
                            <TreeSelect
                                style={{ width: '100%' }}
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                }}
                                treeData={!descriptionFieldsDataLoaded ? (
                                    [{ value: "Carregando", label: "Carregando...", children: [] }]
                                ) : (
                                    descriptionFields.depositionalSystem
                                )}
                                multiple
                                treeLine={{ showLeafIcon: false }}
                                showArrow
                                onChange={depositionalSystemChange}
                            />
                        </Panel>
                    </Collapse>
                    <br />

                    <Collapse>
                        <Panel header={genExtraDate(filterIcon, "Data", dataDateMinFilter, dataDateMaxFilter)} key="0" showArrow={false} style={{ background: 'white' }}>
                            <RangePicker
                                style={{
                                    width: '100%',
                                }}
                                format={dateFormat}
                                onChange={dataDateChange}
                            />
                        </Panel>
                    </Collapse>
                </Drawer>
            </Space>
        </>
    );
}


export default DescriptionsDataFilter;