import React, { useEffect, useState, useContext, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import bellcurve from "highcharts/modules/histogram-bellcurve";
import HighchartsHeatMap from "highcharts/modules/heatmap";
import { Row, Col, Radio, Space, Select } from 'antd';
import { ObjectTypes, ArchitecturalElementTypes as ArchType } from "../Description/CreateDescription/DescriptionObjects/types"
import { store } from '../Store';
import { useAxiosGet } from '../../hooks/useAxios';
import { object } from 'as';

bellcurve(Highcharts);
HC_more(Highcharts);
HighchartsHeatMap(Highcharts);

export const ScatterPlot = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;
    const architecturalTypeColors = state.architecturalTypeColors;
    const LOG = 1, LINEAR = 2;
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        setValue(e.target.value);
    };

    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'scatter',
            zoomType: 'xy'
        },
        title: {
            text: 'Razão de Aspecto',
            align: 'left'
        },
        xAxis: {
            title: {
                text: 'Largura'
            },
            labels: {
                format: '{value} m'
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
            min: 1,
        },
        yAxis: {
            title: {
                text: 'Altura'
            },
            labels: {
                format: '{value} m'
            },
            min: 1,
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 4.5,
                    symbol: 'circle',
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                }
            }
        },
        tooltip: {
            pointFormat: ' {point.name} <br/> Largura: {point.x:.1f} m <br/> Altura: {point.y:.1f} m'
        },
    });

    const getData = (data) => {
        var result = {}

        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    properties,
                    title,
                    children,
                    architecturalElementType,
                } = node;
                const {
                    width,
                    thickness,
                } = properties || {};

                if ((geologicalObjectType === ObjectTypes.ARCH_ELEMENT || geologicalObjectType == ObjectTypes.UNIT) && architecturalElementType) {
                    if (!result[architecturalElementType])
                        result = {...result, [architecturalElementType]: {name: architecturalElementType, data: [], color: architecturalTypeColors[architecturalElementType]}}
                    if (width !== undefined && thickness !== undefined)
                        result[architecturalElementType].data.push({ x: width, y: thickness, name: title });
                }

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
        });

        return Object.values(result);
    };

    useEffect(() => {
        const type = value === LINEAR ? 'linear' : 'logarithmic';
        const tickInterval = value === LINEAR ? '' : 1;
        const minorTickInterval = value === LINEAR ? '' : 0.1;

        setChartOptions({
            series: getData(descriptionDataRetrieval),
            xAxis: {
                ...chartOptions.xAxis,
                type: type,
                tickInterval: tickInterval,
                minorTickInterval: minorTickInterval,
            },
            yAxis: {
                ...chartOptions.yAxis,
                type: type,
                tickInterval: tickInterval,
                minorTickInterval: minorTickInterval,
            }
        });

    }, [value, descriptionDataRetrieval, architecturalTypeColors])

    return (
        <>
            <Row>
                <Col span={20}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </Col>
                <Col span={4}>
                    <Radio.Group onChange={onChange} value={value}>
                        <Space direction="vertical">
                            <Radio value={1}>Logarítimica</Radio>
                            <Radio value={2}>Linear </Radio>
                        </Space>
                    </Radio.Group>
                </Col>
            </Row>
        </>
    )
};

export const PieChart = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;
    const architecturalTypeColors = state.architecturalTypeColors;

    const getData = (data) => {
        var result = {}

        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    children,
                    architecturalElementType,
                } = node;

                if ((geologicalObjectType === ObjectTypes.ARCH_ELEMENT || geologicalObjectType == ObjectTypes.UNIT) && architecturalElementType) {
                    if (!result[architecturalElementType])
                        result = {...result, [architecturalElementType]: {name: architecturalElementType, y: 1, color: architecturalTypeColors[architecturalElementType]}}
                    result[architecturalElementType].y = result[architecturalElementType].y + 1
                }

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
        });

        return Object.values(result);
    };

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: undefined
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Elemento Arquitetural',
            colorByPoint: true,
            data: getData(descriptionDataRetrieval),
        }]
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export const StackedHorizontalCharts = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;
    const architecturalTypeColors = state.architecturalTypeColors;

    let categories = [];
    let result = {};

    // initializng plot variables
    const dataLength = descriptionDataRetrieval.length;
    descriptionDataRetrieval.forEach((e, index) => categories.push(`Descrição ${index + 1}`))

    const getData = (data) => {
        let descriptionIndex = 0;
        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    children,
                    architecturalElementType,
                } = node;

                if ((geologicalObjectType === ObjectTypes.ARCH_ELEMENT || geologicalObjectType == ObjectTypes.UNIT) && architecturalElementType) {
                    if (!result[architecturalElementType])
                        result = {...result, [architecturalElementType]: {name: architecturalElementType, data: Array(dataLength).fill(0), color: architecturalTypeColors[architecturalElementType]}}
                    result[architecturalElementType].data[descriptionIndex]++;
                }

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
            descriptionIndex++
        });
        return Object.values(result)
    };

    const options = {
            chart: {
                type: 'bar'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: categories,
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Porcentagem'
                }
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
            },
            plotOptions: {
                bar: {
                    stacking: 'percent'
                }
            },
            series: getData(descriptionDataRetrieval),
        };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export const HistogramChart = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;
    const architecturalTypeColors = state.architecturalTypeColors;

    // const result = Object.entries(architecturalTypeColors).reduce((acc, [chave, valor]) => {
    //     acc[chave] = {name: chave,
    //                 width: [],
    //                 thickness: [],
    //                 area: [],
    //                 color: architecturalTypeColors[chave]};
    //     return acc;
    // }, {});

    const getData = (data) => {
        var result = {}

        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    properties,
                    children,
                    architecturalElementType,
                } = node;
                const {
                    width,
                    thickness,
                    area,
                    heightMax,
                    heightMean,
                    heightMedian,
                    heightMin,
                    heightMode,
                    widthMax,
                    widthMean,
                    widthMedian,
                    widthMin,
                    widthMode,
                } = properties || {};

                if ((geologicalObjectType === ObjectTypes.ARCH_ELEMENT || geologicalObjectType == ObjectTypes.UNIT) && architecturalElementType) {
                    if (!result[architecturalElementType]) {
                        result = {...result, [architecturalElementType]: {name: architecturalElementType,
                                  width:      [], thickness:   [], area:         [],
                                  heightMax:  [], heightMean:  [], heightMedian: [],
                                  heightMin:  [], heightMode:  [], widthMax:     [],
                                  widthMean:  [], widthMedian: [], widthMin:     [],
                                  widthMode:  [], color: architecturalTypeColors[architecturalElementType]}}
                    }
                    const validProperties = (width      !== undefined && thickness    !== undefined && area         !== undefined &&
                                             heightMax  !== undefined && heightMean   !== undefined && heightMedian !== undefined &&
                                             heightMin  !== undefined && heightMode   !== undefined && widthMax     !== undefined &&
                                             widthMean  !== undefined && widthMedian  !== undefined && widthMin     !== undefined &&
                                             widthMode !== undefined);
                    if (validProperties) {
                        result[architecturalElementType].width.push(width);
                        result[architecturalElementType].thickness.push(thickness);
                        result[architecturalElementType].area.push(area);
                        result[architecturalElementType].heightMax.push(heightMax);
                        result[architecturalElementType].heightMean.push(heightMean);
                        result[architecturalElementType].heightMedian.push(heightMedian);
                        result[architecturalElementType].heightMin.push(heightMin);
                        result[architecturalElementType].heightMode.push(heightMode);
                        result[architecturalElementType].widthMax.push(widthMax);
                        result[architecturalElementType].widthMean.push(widthMean);
                        result[architecturalElementType].widthMedian.push(widthMedian);
                        result[architecturalElementType].widthMin.push(widthMin);
                        result[architecturalElementType].widthMode.push(widthMode);
                    }
                }

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
        });

        return result;
    };
    const data = getData(descriptionDataRetrieval)
    const archTypes = data? Object.keys(data) : {}

    const variableOptions = [
        { value: 'width',        label: 'Largura' },
        { value: 'thickness',    label: 'Espessura' },
        { value: 'area',         label: 'Área' },
        { value: 'heightMax',    label: 'Altura Máxima' },
        { value: 'heightMean',   label: 'Altura Média' },
        { value: 'heightMedian', label: 'Altura Mediana' },
        { value: 'heightMin',    label: 'Altura Mínima' },
        { value: 'heightMode',   label: 'Altura Modal' },
        { value: 'widthMax',     label: 'Largura Máxima' },
        { value: 'widthMean',    label: 'Largura Média' },
        { value: 'widthMedian',  label: 'Largura Mediana' },
        { value: 'widthMin',     label: 'Largura Mínima' },
        { value: 'widthMode',    label: 'Largura Modal' }
    ];
    const [variable, setVariable] = useState(variableOptions[0].value);
    const [archType, setArchType] = useState(data? archTypes[0] : "");
    const onChangeVariable = (value) => {
        setVariable(value);
    };

    const onChangeArchType = (value) => {
        setArchType(value);
    };

    const [filteredData, setFilteredData] = useState(data);
    const [chartOptions, setChartOptions] = useState({
        title: {
            text: 'Váriavel x Elemento Arquitetural'
        },

        xAxis: [{
            title: { text: 'Dados' },
            alignTicks: false
        }, {
            title: { text: 'Histograma' },
            alignTicks: false,
            opposite: true
        }],

        yAxis: [{
            title: { text: 'Dados' }
        }, {
            title: { text: 'Histograma' },
            opposite: true
        }],

        plotOptions: {
            histogram: {
                accessibility: {
                    point: {
                        valueDescriptionFormat: '{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.'
                    }
                }
            }
        },

        series: [{
            name: 'Histograma',
            type: 'histogram',
            xAxis: 1,
            yAxis: 1,
            baseSeries: 's1',
            color: architecturalTypeColors[archType],
            zIndex: -1
        }, {
            name: 'Dados',
            type: 'scatter',
            id: 's1',
            data: filteredData? filteredData[archType][variable] : {},
            color: '#000',
            marker: {
                radius: 1.5,
            }
        }]
    })

    useEffect(() => {
        setFilteredData(getData(descriptionDataRetrieval));
        console.log(Object.keys(filteredData))
    }, [descriptionDataRetrieval])

    useEffect(() => {

        if (filteredData && filteredData[archType][variable].length !== 0)
            setChartOptions(old => ({
                ...old,
                series: [{
                    name: 'Histograma',
                    type: 'histogram',
                    xAxis: 1,
                    yAxis: 1,
                    baseSeries: 's1',
                    color: architecturalTypeColors[archType],
                    zIndex: -1
                }, {
                    name: 'Dados',
                    type: 'scatter',
                    id: 's1',
                    data: filteredData? filteredData[archType][variable] : {},
                    color: '#000',
                    marker: {
                        radius: 1.5
                    }
                }]
            }));
        else
            setChartOptions(old => ({
                ...old,
                series: [{
                    name: 'Histograma',
                    type: 'histogram',
                    xAxis: 1,
                    yAxis: 1,
                    baseSeries: 's1',
                    color: architecturalTypeColors[archType],
                    zIndex: -1
                }, {
                    name: 'Dados',
                    type: 'column',
                    id: 's1',
                    data: filteredData? filteredData[archType][variable] : {},
                    color: '#000',
                    marker: {
                        radius: 1.5
                    }
                }]
            }));

    }, [filteredData])


    useEffect(() => {
        if (!filteredData || filteredData[archType][variable].length === 0)
            return;

        setChartOptions((prevChartOptions) => {
            // Clone the existing series array
            const updatedSeries = [...prevChartOptions.series];

            updatedSeries[0] = {
                ...updatedSeries[0],
                color: architecturalTypeColors[archType],
            }

            // Update the data property of the second series
            updatedSeries[1] = {
                ...updatedSeries[1], // Clone the existing object
                data: filteredData[archType][variable], // Update the data property
            };

            // Return the updated chartOptions with the modified series array
            return {
                ...prevChartOptions, // Clone the existing chartOptions
                series: updatedSeries, // Update the series array
            };
        });
    }, [archType, variable, architecturalTypeColors]);

    return (
        <>
            <Row>
                <Col span={20}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </Col>
                <Col span={4}>
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Select
                            style={{ width: 200 }}
                            placeholder="Selecione um tipo de Variável"
                            defaultValue={variableOptions[0].label}
                            value={variable}
                            onChange={onChangeVariable}
                            options={variableOptions}
                        >
                        </Select>
                        <Select
                            style={{ width: 200 }}
                            placeholder="Selecione um tipo de Elemento"
                            defaultValue={archTypes ? archTypes[0] : ''}
                            value={archType}
                            onChange={onChangeArchType}
                        >
                            {Object.keys(filteredData).map((option, index) => (
                                <Select.Option key={index} value={option}>
                                    {option}
                                </Select.Option>
                            ))}
                        </Select>
                    </Space>
                </Col>
            </Row>
        </>
    )
};

export const BarChart = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;
    const architecturalTypeColors = state.architecturalTypeColors;

    const getData = (data) => {
        var result = {}

        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    children,
                    architecturalElementType,
                } = node;

                if ((geologicalObjectType === ObjectTypes.ARCH_ELEMENT || geologicalObjectType == ObjectTypes.UNIT) && architecturalElementType) {
                    if (!result[architecturalElementType])
                        result = {...result, [architecturalElementType]: {y: 0, color: architecturalTypeColors[architecturalElementType]}}
                    result[architecturalElementType].y += 1;
                }

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
        });

        return result;
    };

    // Obtenha os dados e atribua cores
    const dataObject = getData(descriptionDataRetrieval);
    const categories = Object.keys(dataObject);
    const data = Object.values(dataObject);

    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: 'Frequência por elemento arquitetural',
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            title: {
                text: 'Frequência',
            },
        },
        series: [
            {
                name: 'Dados',
                data: data,
                showInLegend: false
            },
        ],
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};


export const HeatMap = () => {
    //Geting data from backend
    const descriptionInfoURL = "http://api-geoserver.inf.ufrgs.br/description/data";
    const urlGeologicalObjects = 'geologicalObjects';
    const urlArchitecturalElement = 'architecturalElement';
    const urlBaseGeometry = 'bottomGeometry';
    const urlGeometryType = 'typeGeometry';
    const urlTopGeometry = 'topGeometry';
    const { data: architecturalElementData, error: architecturalElementDataError, loaded: architecturalElementDataLoaded } = useAxiosGet(descriptionInfoURL, sessionStorage);

    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;
    const architecturalTypeColors = state.architecturalTypeColors;

    const architecturalElement = useMemo(() => {
        const generateList = (data) => {
            let tempList = [];
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                let newNode = {}
                newNode.title = node.labels.pt[0]
                newNode.value = node.iri;
                if (node.types)
                    newNode.children = generateList(node.types)
                else
                    newNode.children = [];
                tempList.push(newNode);
            }
            return tempList;
        };

        let parsedArchitecturalElementFields = {};

        if (architecturalElementData) {
            let geometryType = architecturalElementData[urlGeologicalObjects][urlArchitecturalElement][urlGeometryType].types;
            parsedArchitecturalElementFields.geometryType = generateList(geometryType);

            return parsedArchitecturalElementFields;
        }


    }, [architecturalElementData]);

    //Geometry data
    const categories = Object.keys(architecturalTypeColors);

    let geometriesList = [];

    const addToUniqueList = (list, element) => {
        if (!list.includes(element)) {
            list.push(element);
        }
    }

    const findTitleByValue = (dictionaryList, targetValue) => {
        for (let i = 0; i < dictionaryList.length; i++) {
            const dictionary = dictionaryList[i];
            if (dictionary.value === targetValue) {
                return dictionary.title;
            }
        }

        return null; // Return null if the target value is not found in any dictionary.
    }

    const getData = (data) => {
        //const result = Array(categories.length).fill(0)

        const result = Object.fromEntries(categories.map(category => [category, {}]));
        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    children,
                    architecturalElementType,
                } = node;

                //if (geologicalObjectType === ObjectTypes.ARCH_ELEMENT) {
                let found = categories.indexOf(architecturalElementType);
                if (found !== -1 && architecturalElementDataLoaded)
                    if ("typeGeometry" in node["properties"]) {
                        let typeGeometry = findTitleByValue(architecturalElement.geometryType, node["properties"]["typeGeometry"]);

                        if (!(typeGeometry in result[architecturalElementType])) {
                            result[architecturalElementType][typeGeometry] = 1;
                            addToUniqueList(geometriesList, typeGeometry);
                        }
                        else {
                            result[architecturalElementType][typeGeometry]++;
                        }
                    }
                    else {
                        let other = "não informada";
                        if (!(other in result[architecturalElementType])) {
                            result[architecturalElementType][other] = 1;
                            addToUniqueList(geometriesList, other);
                        }
                        else {
                            result[architecturalElementType][other]++;
                        }
                    }
                //}

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
        });

        Object.keys(result).forEach((category) => {
            if (Object.keys(result[category]).length === 0) {
                delete result[category];
            }
        });

        

        const matriz = [];

        categories.forEach((category, categoryIndex) => {
            geometriesList.forEach((geometry, geometryIndex) => {
                if (result[category] && result[category][geometry] !== undefined) {
                    const valor = result[category][geometry];
                    matriz.push([categoryIndex, geometryIndex, valor]);
                } else {
                    matriz.push([categoryIndex, geometryIndex, 0]);
                }
            });
        });

        return matriz;
    };

    let histogramData = getData(descriptionDataRetrieval);

    const options = {
        chart: {
            type: 'heatmap',
        },
        title: {
            text: 'Frequência de tipo de geometria por elemento arquitetural',
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            categories: geometriesList,
            title: {
                text: '',
            },
        },
        colorAxis: {
            min: 0,
            minColor: '#FFFFFF',
            maxColor: Highcharts.getOptions().colors[0],
            stops: [
                [0, '#FFFFFF'], // Define the color for the lowest value
                [0.5, '#FF0000'], // Define the color for the middle value
                [1, '#000000'] // Define the color for the highest value
            ],
            width: 100
        },
        series: [
            {
                name: 'Dados',
                data: histogramData,
                dataLabels: {
                    enabled: true,
                    color: '#000000',
                    formatter: function () {
                        return this.point.value; // Display only the value
                    }
                }
            },
        ],
        tooltip: {
            enabled: false
        },
    };

    return (

        <><span>
            {!architecturalElementDataLoaded ? (
                "Carregando..."
            ) : (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options} />
            )}
        </span></>
    );
};

export const BoxPlot = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;
    const architecturalTypeColors = state.architecturalTypeColors;

    const variableOptions = [
        { value: 'width',        label: 'Largura' },
        { value: 'thickness',    label: 'Espessura' },
        { value: 'area',         label: 'Área' },
        { value: 'heightMax',    label: 'Altura Máxima' },
        { value: 'heightMean',   label: 'Altura Média' },
        { value: 'heightMedian', label: 'Altura Mediana' },
        { value: 'heightMin',    label: 'Altura Mínima' },
        { value: 'heightMode',   label: 'Altura Modal' },
        { value: 'widthMax',     label: 'Largura Máxima' },
        { value: 'widthMean',    label: 'Largura Média' },
        { value: 'widthMedian',  label: 'Largura Mediana' },
        { value: 'widthMin',     label: 'Largura Mínima' },
        { value: 'widthMode',    label: 'Largura Modal' }
    ];
    const [variable, setVariable] = useState(variableOptions[0].value);
    const onChangeVariable = (value) => {
        setVariable(value);
        console.log(value)
    };

    const getData = (data) => {
        const result = {
            width: {},
            thickness: {},
            area: {},
            heightMax: {},
            heightMean: {},
            heightMedian: {},
            heightMin: {},
            heightMode: {},
            widthMax: {},
            widthMean: {},
            widthMedian: {},
            widthMin: {},
            widthMode: {},
        };

        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    properties,
                    title,
                    children,
                    architecturalElementType,
                } = node;
                const {
                    width,
                    thickness,
                    area,
                    heightMax,
                    heightMean,
                    heightMedian,
                    heightMin,
                    heightMode,
                    widthMax,
                    widthMean,
                    widthMedian,
                    widthMin,
                    widthMode,
                } = properties || {};

                if ((geologicalObjectType === ObjectTypes.ARCH_ELEMENT || geologicalObjectType == ObjectTypes.UNIT) && architecturalElementType) {
                    if (!result.width[architecturalElementType]){
                        result.width = {...result.width, [architecturalElementType]: []}
                        result.thickness = {...result.thickness, [architecturalElementType]: []}
                        result.area = {...result.area, [architecturalElementType]: []}

                        result.heightMax = {...result.heightMax, [architecturalElementType]: []}
                        result.heightMean = {...result.heightMean, [architecturalElementType]: []}
                        result.heightMedian = {...result.heightMedian, [architecturalElementType]: []}

                        result.heightMin = {...result.heightMin, [architecturalElementType]: []}
                        result.heightMode = {...result.heightMode, [architecturalElementType]: []}
                        result.widthMax = {...result.widthMax, [architecturalElementType]: []}

                        result.widthMean = {...result.widthMean, [architecturalElementType]: []}
                        result.widthMedian = {...result.widthMedian, [architecturalElementType]: []}
                        result.widthMin = {...result.widthMin, [architecturalElementType]: []}
                        result.widthMode = {...result.widthMode, [architecturalElementType]: []}
                    }
                    const validProperties = (width      !== undefined && thickness    !== undefined && area         !== undefined &&
                        heightMax  !== undefined && heightMean   !== undefined && heightMedian !== undefined &&
                        heightMin  !== undefined && heightMode   !== undefined && widthMax     !== undefined &&
                        widthMean  !== undefined && widthMedian  !== undefined && widthMin     !== undefined &&
                        widthMode !== undefined);
                    if (validProperties) {
                        result.width[architecturalElementType].push(width);
                        result.thickness[architecturalElementType].push(thickness);
                        result.area[architecturalElementType].push(area);
                        result.heightMax[architecturalElementType].push(heightMax);
                        result.heightMean[architecturalElementType].push(heightMean);
                        result.heightMedian[architecturalElementType].push(heightMedian);
                        result.heightMin[architecturalElementType].push(heightMin);
                        result.heightMode[architecturalElementType].push(heightMode);
                        result.widthMax[architecturalElementType].push(widthMax);
                        result.widthMean[architecturalElementType].push(widthMean);
                        result.widthMedian[architecturalElementType].push(widthMedian);
                        result.widthMin[architecturalElementType].push(widthMin);
                        result.widthMode[architecturalElementType].push(widthMode);
                    }
                }

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
        });

        console.log(result)

        return result;
    };

    let filteredData = getData(descriptionDataRetrieval);
    const categories = Object.keys(filteredData.width);
    const [boxplotData, setBoxplotData] = useState(getBoxPlotData(filteredData));

    useEffect(() => {
        filteredData = getData(descriptionDataRetrieval)
        setBoxplotData(getBoxPlotData(filteredData));
        

    }, [descriptionDataRetrieval])

    function getBoxPlotData(data) {
        let boxplotData = {
            width: new Array(categories.length).fill([]),
            thickness: new Array(categories.length).fill([]),
            area: new Array(categories.length).fill([]),
            heightMax: new Array(categories.length).fill([]),
            heightMean: new Array(categories.length).fill([]),
            heightMedian: new Array(categories.length).fill([]),
            heightMin: new Array(categories.length).fill([]),
            heightMode: new Array(categories.length).fill([]),
            widthMax: new Array(categories.length).fill([]),
            widthMean: new Array(categories.length).fill([]),
            widthMedian: new Array(categories.length).fill([]),
            widthMin: new Array(categories.length).fill([]),
            widthMode: new Array(categories.length).fill([]),
        }
        // Iterate through keys (width, thickness, area)
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const subData = data[key];
                // Iterate through sub-arrays (e.g., [ArchType.CHANNEL], [ArchType.LOBE_SPRAWL], ...)
                for (const archType in subData) {
                    if (subData.hasOwnProperty(archType)) {
                        const data = subData[archType];
                        if (data.length > 0) {
                            const boxPlotValues = calculateBoxPlotValues(data);
                            //console.log(`Box plot values for ${key} - ${archType}:`, boxPlotValues);
                            boxplotData[key][categories.indexOf(archType)] = boxPlotValues
                        }
                    }
                }
            }
        }
        return boxplotData;
    }

    // Function to calculate box plot values
    function calculateBoxPlotValues(data) {
        const sortedData = data.sort((a, b) => a - b);
        const n = sortedData.length;
        const median = n % 2 === 0 ? (sortedData[n / 2 - 1] + sortedData[n / 2]) / 2 : sortedData[Math.floor(n / 2)];
        const lowerHalf = sortedData.slice(0, n / 2);
        const upperHalf = sortedData.slice(n / 2 + (n % 2 === 0 ? 0 : 1));
        const q1 = lowerHalf.length % 2 === 0 ? (lowerHalf[lowerHalf.length / 2 - 1] + lowerHalf[lowerHalf.length / 2]) / 2 : lowerHalf[Math.floor(lowerHalf.length / 2)];
        const q3 = upperHalf.length % 2 === 0 ? (upperHalf[upperHalf.length / 2 - 1] + upperHalf[upperHalf.length / 2]) / 2 : upperHalf[Math.floor(upperHalf.length / 2)];
        const min = sortedData[0];
        const max = sortedData[n - 1];

        return [
            min,
            q1,
            median,
            q3,
            max,
        ];
    }

    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'boxplot',
        },
        title: {
            text: '',
        },
        legend: {
            enabled: false
        },
        tooltip: {
            formatter: function () {
                const point = this.point;
                return `
                <b>Tipo:</b> ${point.category}<br>
                <b>Máximo:</b> ${point.high.toFixed(2)}<br>
                <b>Q3:</b> ${point.q3.toFixed(2)}<br>
                <b>Mediana:</b> ${point.median.toFixed(2)}<br>
                <b>Q1:</b> ${point.q1.toFixed(2)}<br>
                <b>Mínimo:</b> ${point.low.toFixed(2)}<br>
              `;
            }
        },
        xAxis: {
            categories: categories,
            title: {
                text: 'Tipo de elemento arquitetural',
            },
        },
        yAxis: {
            title: {
                text: 'Valores',
            },
        },
        series: [
            {
                name: 'Box Plot',
                data: boxplotData[variable],
            },
        ],
    })
    
    useEffect(() => {
        const reduceBrightness = (hexColor, factor) => {
            // Check if hexColor is a valid hex string
            if (!/^#[0-9A-Fa-f]{6}$/i.test(hexColor)) {
                console.warn(`Invalid hex color: ${hexColor}. Using default color.`);
                hexColor = "#000000";  // Fallback to black if color is invalid
            }
            
            const rgb = hexColor.match(/\w\w/g).map((x) => parseInt(x, 16));
            const adjustedRgb = rgb.map((channel) => Math.floor(channel * factor));
            return `#${adjustedRgb.map((x) => x.toString(16).padStart(2, '0')).join('')}`;
        };

        const seriesData = boxplotData[variable].map((data, index) => ({
            name: categories[index],
            data: data.map((point, i) => ({
                ...point, // Preserva as propriedades de cada ponto
                color: architecturalTypeColors[categories[i]], // Cor específica para cada boxplot
            })),
            color: architecturalTypeColors[categories[index]],
            fillColor: architecturalTypeColors[categories[index]], // Cor de preenchimento da caixa
            medianColor: reduceBrightness(architecturalTypeColors[categories[index]], 0.5), // Cor da linha mediana com menos brilho
            stemColor: reduceBrightness(architecturalTypeColors[categories[index]], 0.5),   // Cor das hastes com menos brilho
            whiskerColor: reduceBrightness(architecturalTypeColors[categories[index]], 0.5), // Cor dos whiskers com menos brilho
        }));
    
        setChartOptions({
            chart: {
                type: boxplotData[variable].every(subList => subList.length === 0) ? "column" : 'boxplot',
            },
            title: {
                text: '',
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    const point = this.point;
                    return `
                    <b>Tipo:</b> ${point.category}<br>
                    <b>Máximo:</b> ${point.high.toFixed(2)}<br>
                    <b>Q3:</b> ${point.q3.toFixed(2)}<br>
                    <b>Mediana:</b> ${point.median.toFixed(2)}<br>
                    <b>Q1:</b> ${point.q1.toFixed(2)}<br>
                    <b>Mínimo:</b> ${point.low.toFixed(2)}<br>
                  `;
                }
            },
            xAxis: {
                categories: categories,
                title: {
                    text: 'Tipo de elemento arquitetural',
                },
            },
            yAxis: {
                title: {
                    text: 'Valores',
                },
            },
            series: [
                {
                    name: 'Box Plot',
                    data: boxplotData[variable],
                    color: '#000000'
                }
            ],
        });
    }, [variable, boxplotData, architecturalTypeColors]);

    return (
        <>
            <Row>
                <Col span={20}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </Col>
                <Col span={4}>
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Select
                            style={{ width: 200 }}
                            placeholder="Selecione um tipo de Variável"
                            defaultValue={variableOptions[0].label}
                            value={variable}
                            onChange={onChangeVariable}
                            options={variableOptions}
                        >
                        </Select>
                    </Space>
                </Col>
            </Row>
        </>
    )
};