import React, { useState, useEffect, useContext } from 'react';
import { PieChart, ScatterPlot, StackedHorizontalCharts, HistogramChart, BarChart, BoxPlot, HeatMap } from '../Charts';
import { Button, Dropdown, Menu, Card, Collapse, Space, Row, Col, Tooltip, Affix } from 'antd';
import Swal from "sweetalert2";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import "../VisualAnalysisContainer/index.css";
import { setDescriptionDataRetrieval, updateDescriptionDataCharts, deleteDescriptionCharts, setDescriptionDataCharts } from "../Store/actions";
import { store } from '../Store';
import { ColorAxis } from 'highcharts';
import { updateArchitecturalTypeColors } from "../Store/actions";

const { Panel } = Collapse;
const SCATTER = 0, PROPORTION = 1, HISTOGRAM = 2, BOXPLOT = 3, HEATMAP = 4;
const numberToChartType = ["Dispersão", "Proporção", "Histograma", "Boxplot", "Heatmap"];

const VisualAnalysisContainer = ({ data }) => {

    const globalState = useContext(store);
    const { state, dispatch } = globalState;

    useEffect(() => {
        dispatch(setDescriptionDataRetrieval(cleanData(data)));
    }, [data])

    const genereteComponent = (option, key) => {
        // Create a new collapsible component for the selected option
        const newComponent = (
            <div key={key}>
                <Collapse defaultActiveKey={['1']}>
                    <Panel
                        header={
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <span>{numberToChartType[option]}</span>
                                <Tooltip title="Remover gráfico" style={{color: "blue"}}>
                                    <Button
                                        type="link"
                                        danger  
                                        icon={<DeleteOutlined/>}
                                        onClick={(e) => handleDelete(key, e)}
                                    />
                                </Tooltip>
                            </div>
                        }
                        key="1"   
                    >
                        <Row justify="space-around" align="middle">
                            {option === SCATTER &&
                                (<>
                                    <Col span={23}>
                                        <ScatterPlot />
                                    </Col>
                                </>)
                            }
                            {option === PROPORTION &&
                                (<>
                                    <Col span={11}>
                                        <PieChart />
                                    </Col>
                                    <Col span={11}>
                                        <StackedHorizontalCharts />
                                    </Col>
                                </>
                                )
                            }
                            {option === HISTOGRAM &&
                                (<>
                                    <Col span={11}>
                                        <HistogramChart />
                                    </Col>
                                    <Col span={6}>
                                        <BarChart />
                                    </Col>
                                </>)
                            }
                            {option === HEATMAP &&
                                (<>
                                    <Col span={23}>
                                        <HeatMap />
                                    </Col>
                                </>)
                            }
                            {option === BOXPLOT &&
                                (<>
                                    <Col span={23}>
                                        <BoxPlot />
                                    </Col>
                                </>)
                            }
                        </Row>
                    </Panel>
                </Collapse>
            </div>
        );

        return newComponent;
    };

    const [collapsibleComponents, setCollapsibleComponents] = useState({
        "component": [],
        "key": [],
        "option": [],
    });


    useEffect(() => {
        setCollapsibleComponents(
            {
                "component": state.descriptionCharts["key"].map((_, index) => genereteComponent(state.descriptionCharts["option"][index], state.descriptionCharts["key"][index])),
                "key": state.descriptionCharts["key"],
                "option": state.descriptionCharts["option"],
            }
        )
    }, [state.descriptionCharts])

    const updateComponents = (newOption) => {

        if (Object.keys(newOption).length !== 0) {
            /*
            dispatch(setDescriptionDataCharts(collapsibleComponent => (
                {
                    "component": [...collapsibleComponent["component"], genereteComponent(newOption["option"], newOption["key"])],
                    "key": [...collapsibleComponent["key"], newOption["key"]],
                    "option": [...collapsibleComponent["option"], newOption["option"]]
                }
            )
            ));*/

            let send = {
                "key": newOption["key"],
                "component": genereteComponent(newOption["option"], newOption["key"]),
                "option": newOption["option"]
            };

            dispatch(updateDescriptionDataCharts(send))
        }

    }

    const handleOptionClick = (option) => {
        // Create a unique key for each collapsible component
        const key = Date.now().toString();

        //List of options
        let newOption = { "option": option, "key": key };
        updateComponents(newOption);
    }

    const handleDelete = (unwantedKeyValue, e) => {
        e.stopPropagation();
        Swal.fire({
            title: 'Você tem certeza?',
            text: "Não será possível reverter isto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, apague!',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                let index  = state.descriptionCharts["key"].findIndex(key => key === unwantedKeyValue);

                if(index.length === 0 )
                    index = state.descriptionCharts["key"].length;
        
                dispatch(deleteDescriptionCharts(index));
            }
        })
    };


    const menu = (
        <Menu>
            <Menu.Item key="option1" onClick={() => handleOptionClick(SCATTER)}>
                Dispersão
            </Menu.Item>
            <Menu.Item key="option2" onClick={() => handleOptionClick(PROPORTION)}>
                Proporção
            </Menu.Item>
            <Menu.Item key="option3" onClick={() => handleOptionClick(HISTOGRAM)}>
                Histograma
            </Menu.Item>
            <Menu.Item key="option4" onClick={() => handleOptionClick(BOXPLOT)}>
                Boxplot
            </Menu.Item>
            <Menu.Item key="option5" onClick={() => handleOptionClick(HEATMAP)}>
                Heatmap
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Collapse defaultActiveKey={['2']}>
                <Panel header={<div style={{fontSize: '16px'}}>Análise Visual</div>}
                    key="2"
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{
                            display: 'flex',
                        }}>
                        {collapsibleComponents.component.length > 0 ? (
                            collapsibleComponents.component
                        ) : (
                            <div>Para adicionar um gráfico, clique no botão azul no canto inferior direito da tela.</div>
                        )}
                    </Space>
                </Panel>
            </Collapse>
            <div style={{ position: 'fixed', right: 20, bottom: '5%'}}>
                <Dropdown overlay={menu} trigger={['click']} placement={"topLeft"}>
                    <Button size={"large"} type="primary" shape="circle">
                        <PlusOutlined style={{ display: 'inline-flex' }} />
                    </Button>
                </Dropdown>
            </div>
        </>
    )
};

function cleanData(data) {
    const newData = [];

    data.forEach(element => {
        newData.push(element.geoDescriptionStored.descriptionObjects.objects)
    });

    return newData;
}

export default VisualAnalysisContainer;
