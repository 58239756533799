import { React, useContext, useEffect, useState } from 'react';
import { store } from '../../Store';
import { setDescriptionInformationAction, setDescriptionObjectsAction, setEditingDescriptionAction } from '../../Store/actions';
import { Link } from 'react-router-dom';
import { Table, Space, Divider, Spin, Collapse } from 'antd'
import { EyeOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';


import "./DescriptionTable.css"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Panel } = Collapse;


function DescriptionTable({ descriptionsDataFiltered, descriptionsDataLoaded, descriptionFieldsData }) {
    const globalState = useContext(store);
    const { dispatch } = globalState;

    const [basins, setBasins] = useState({ labels: { pt: [] } });

    useEffect(() => {
        if (descriptionFieldsData !== null)
            setBasins(descriptionFieldsData["geologicalContext"]["watershed"].types)
    }, [descriptionFieldsData])

    const columns = [
        {
            title: 'Sítio geológico',
            render: item => item.geoDescriptionStored.descriptionInformation.geologicalSiteName,
            width: '20%',
        },
        {
            title: 'Tipo',
            render: item => item.geoDescriptionStored.descriptionInformation.descriptionType,
            width: '10%',
        },
        {
            title: 'País',
            render: item => item.geoDescriptionStored.descriptionInformation.country,
            width: '10%',
        },
        {
            title: 'Sistema deposicional',
            render: item => item.geoDescriptionStored.descriptionInformation.depositionalSystem,
            width: '10%',
        },
        {
            title: 'Geocronologia',
            render: item => item.geoDescriptionStored.descriptionInformation.geochronology,
            width: '10%',
        },
        {
            title: 'Bacia',
            render: item => item.geoDescriptionStored.descriptionInformation.watershed ? basins[item.geoDescriptionStored.descriptionInformation.watershed].labels.pt[0] : "",
            width: '10%',
        },
        {
            title: 'Autor',
            render: item => item.geoDescriptionStored.descriptionInformation.author,
            width: '10%',
        },
        {
            title: 'Data',
            render: item => item.geoDescriptionStored.descriptionInformation.dataDate,
            width: '10%',
        },
        {
            title: 'Ações',
            render: (_, item, index) => (
                <Space size="small">
                    <Link
                        onClick={() => {
                            dispatch(setEditingDescriptionAction(false));
                        }}
                        to={{ pathname: `/rdescription/${index}` }}
                    >
                        <Space>
                            <EyeOutlined style={{ display: 'inline-flex' }} />
                            Visualizar
                        </Space>
                    </Link>

                    <Divider type="vertical" />

                    <Link
                        onClick={() => {
                            dispatch(setDescriptionInformationAction(item.geoDescriptionStored.descriptionInformation));
                            dispatch(setDescriptionObjectsAction(item.geoDescriptionStored.descriptionObjects));
                            dispatch(setEditingDescriptionAction(true));
                        }}
                        to="/cdescription"
                    >
                        <Space>
                            <EditOutlined style={{ display: 'inline-flex' }} />
                            Editar
                        </Space>
                    </Link>
                </Space>
            ),
            width: '10%',
        },
    ];

    return (
        <>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Collapse defaultActiveKey={['1']}>
                    <Panel header={<div style={{ fontSize: '16px' }}>Descrições</div>}
                        key="1"
                    >
                        <Table
                            columns={columns}
                            dataSource={descriptionsDataFiltered}
                            pagination={{ pageSize: 20 }}
                            loading={{ indicator: <Spin indicator={antIcon} />, spinning: !descriptionsDataLoaded }}
                            bordered
                        />
                    </Panel>
                </Collapse>
            </Space>
        </>
    );
}


export default DescriptionTable;