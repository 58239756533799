import { useContext, useState, useRef, useEffect } from "react";
import { store } from '../../../../Store';
import { setSelectingSegment, updateSegments, updateContacts } from '../../../../Store/actions';
import ObjectsTree from "./ObjectsTree";
import { useNavigate } from "react-router-dom";
import { SmallDashOutlined, SearchOutlined, DeleteOutlined, PlusOutlined, ApartmentOutlined, VerticalAlignMiddleOutlined, DownOutlined, RightOutlined, ClusterOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Card, Button, Tooltip, message } from 'antd';
import SegmentsForm from "../SegmentsForm";
import AddObjectModal from "./addObjectModal";
import "./index.css"
import { debounce, throttle } from 'lodash';
import ContactsForm from "../ContactsForm";
/*
    This function has two returns: [areSiblings, parent]
    if they are not siblings, areSibling is false and parent null
    if they are siblings but both are root, areSibling is true and parent null
    if they are siblings and not root, the areSibling is true and parent is the parent key
*/
function siblings(data, keys, parent=null) {
    if (!data || data.length === 0) return [false, null];
    const childs = data.map(child => child.key);
    const hasAllNodes = keys.every(key => childs.includes(key));

    if (hasAllNodes)
        return [true, parent];

    for (const node of data) {
        const [areSiblings, parentKey] = siblings(node.children, keys, node.key);
        if (areSiblings)
            return [true, parentKey];
    }

    return [false, null];
}

function createJson(node, order) {
    return { key: node.key + "_" + order, original_key: node.key, title: node.title, checkable: false };
}

const findObj = (data, key) => {
    let result = null;
    const findData = (data, key) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i].children)
                findData(data[i].children, key);
            if (data[i].key === key) {
                result = data[i];
            }
        }

        return result;
    }

    return findData(data, key);
}

function GeoObjectForm(props) {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const { viewOnly } = props
    const dividerHeight = 10;
    const cardHeaderHeight = 39;
    const [openSegmentsSearch, setOpenSegmentsSearch] = useState(false);
    const [openContactsSearch, setOpenContactsSearch] = useState(false);
    const [openSearchBar, setOpenSearchBar] = useState(false);
    const [deletingObjects, setDeletingObjects] = useState(false);
    const [deletingSegments, setDeletingSegments] = useState(false);
    const [deletingContacts, setDeletingContacts] = useState(false);
    const [open, setOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const parentRef = useRef(null);
    const mergeRef = useRef(null);
    const [objectsHeight, setObjectsHeight] = useState((window.innerHeight - (cardHeaderHeight * 3) - (dividerHeight * 2)) / 3);
    const [segmentsHeight, setSegmentsHeight] = useState((window.innerHeight - (cardHeaderHeight * 3) - (dividerHeight * 2)) / 3);
    const [contactsHeigth, setContactsHeigth] = useState((window.innerHeight - (cardHeaderHeight * 3) - (dividerHeight * 2)) / 3);
    const [objectsHide, setObjectsHide] = useState(false);
    const [segmentsHide, setSegmentsHide] = useState(false);
    const [contactsHide, setContactsHide] = useState(false);
    const objectsCardRef = useRef(null);
    const segmentsCardRef = useRef(null);
    const contactsCardRef = useRef(null);

    const navigate = useNavigate();

    //If dont have a description
    if (!localStorage.descriptionInformation)
        navigate("./cdescription");

    useEffect(() => {
        let visibleCount = 0;

        if (!objectsHide) visibleCount++;
        if (!segmentsHide) visibleCount++;
        if (!contactsHide) visibleCount++;

        const height = (window.innerHeight - (cardHeaderHeight * 3) - (dividerHeight * 2)) / visibleCount;

        setObjectsHeight(objectsHide ? 1 : height);
        setSegmentsHeight(segmentsHide ? 1 : height);
        setContactsHeigth(contactsHide ? 1 : height);
    }, [objectsHide, segmentsHide, contactsHide, window.innerHeight]);

    // useEffect(() => {
    //     if (segmentsHide) {
    //         setObjectsHeight(current => current + segmentsHeight)
    //     } else {
    //         setObjectsHeight(current => current - segmentsHeight)
    //     }
    // }, [segmentsHide])

    // useEffect(() => {
    //     if (contactsHide)
    //         setContactsHeigth(0);
    // }, [contactsHide])

    const updateHeights = throttle((newHeights, id) => {
        if (id === "divider1") {
            setObjectsHeight(newHeights.objects);
            setSegmentsHeight(newHeights.segments);
        } else if (id === "divider2") {
            setSegmentsHeight(newHeights.segments);
            setContactsHeigth(newHeights.contacts);
        }
    }, 100);

    const handleMouseMove = (e, startY, initialHeights, id, totalHeight) => {
        const delta = e.clientY - startY;

        // Ensure the sum of the heights remains constant while resizing
        const newHeights = { ...initialHeights };

        if (id === "divider1") {
            newHeights.objects = Math.min(Math.max(initialHeights.objects + delta, 0), totalHeight);
            newHeights.segments = totalHeight - newHeights.objects;
            objectsCardRef.current.children[1].style.height = `${newHeights.objects}px`;
            segmentsCardRef.current.children[1].style.height = `${newHeights.segments}px`;
        } else if (id === "divider2") {
            newHeights.segments = Math.min(Math.max(initialHeights.segments + delta, 0), totalHeight);
            newHeights.contacts = totalHeight - newHeights.segments;
            segmentsCardRef.current.children[1].style.height = `${newHeights.segments}px`;
            contactsCardRef.current.children[1].style.height = `${newHeights.contacts}px`;
        }

        updateHeights(newHeights, id);
    };

    const handleMouseDown = (e, id) => {
        e.preventDefault();

        if (!objectsCardRef || !segmentsCardRef || !contactsCardRef) return;

        document.body.style.cursor = "ns-resize";
        const startY = e.clientY;
        const initialHeights = { objects: objectsHeight, segments: segmentsHeight, contacts: contactsHeigth };

        // Set the total height that should remain constant during resizing
        const totalHeight = (
            id === "divider1"
            ? initialHeights.objects + initialHeights.segments
            : initialHeights.segments + initialHeights.contacts
        );

        const onMouseMove = (event) => handleMouseMove(event, startY, initialHeights, id, totalHeight);
        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            document.body.style.cursor = "default";
            updateHeights.flush();
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const messageError = (error) => messageApi.open({
        type: 'error',
        content: error,
    });

    const mergeObjects = () => {
        const objects = state.descriptionObjects.objects;
        const selecteds = state.descriptionObjects.selectedObject.map(key => findObj(objects, key));
        const [areSiblings, parent] = siblings(objects, state.descriptionObjects.selectedObject);
        const allSameGeologicalObject = selecteds.every((obj, _, arr) =>
            obj.geologicalObjectType === arr[0].geologicalObjectType
        );

        if (!allSameGeologicalObject) {
            messageError('Objetos devem possuir o mesmo tipo geológico.');
            return;
        }
        if (!areSiblings) {
            messageError('Objetos devem pertencer a mesma escala.');
            return;
        }

        parentRef.current = parent;
        mergeRef.current = state.descriptionObjects.selectedObject;
        setOpen(true);
    }

    const createSegment = () => {
        const order = state.descriptionObjects.segment.nextId + 1;
        const objects = state.descriptionObjects.objects;
        const children = state.descriptionObjects.selectedObject
            .map(key => findObj(objects, key))
            .map(node => createJson(node, order));

        const title = "Segmentos de Contatos " + order;
        const key = "segmentSegment" + order;

        const node = {
            title: title,
            key: key,
            children: children,
            typeSegment: '',
            icon: <></>,
        };

        dispatch(updateSegments({
            tree: [...state.descriptionObjects.segment.tree, node],
            nextId: state.descriptionObjects.segment.nextId + 1
        }));
    };

    const createContact = () => {
        const order = state.descriptionObjects.contacts.nextId + 1;
        const segmentTree = state.descriptionObjects.segment.tree;
        const segments = state.descriptionObjects.segment.selectedSegment
            .map(key => findObj(segmentTree, key))

        const areAllSegments = segments.every(segm => segm.children && segm.children.length === 2);

        if (!areAllSegments) {
            messageError('Para criar um contato, selecione apenas segmentos.');
            return;
        }

        const node = {
            title: "Contato " + order,
            key: "contact" + order,
            children: segments.map((node) => createJson(node, order)),
        }

        dispatch(updateContacts({
            tree: [...state.descriptionObjects.contacts.tree, node],
            nextId: state.descriptionObjects.contacts.nextId + 1
        }));
    }

    const menuObjects = [
        <Tooltip title="Adicionar">
            <Button type="link" size="small"
                icon={<PlusOutlined className={`icon ${open ? 'glow' : ''}`} style={{ display: "inline-flex" }} />}
                onClick={() => { parentRef.current = null; mergeRef.current = null; setOpen(true); }}
            />
        </Tooltip>,

        <Tooltip title="Pesquisar">
            <Button type="link" size="small"
                icon={<SearchOutlined className={`icon ${openSearchBar ? 'glow' : ''}`} style={{ display: "inline-flex" }} />}
                onClick={() => { setOpenSearchBar(!openSearchBar) }}
            />
        </Tooltip>,

        <Tooltip title="Remover">
            <Button type="link" size="small"
                icon={<DeleteOutlined className={`icon ${deletingObjects ? 'glow' : ''}`} style={{ display: "inline-flex" }} />}
                onClick={() => { setDeletingObjects(!deletingObjects) }}
                disabled={(state.descriptionObjects.segment.selectingSegment !== null)}
            />
        </Tooltip>,

        <Tooltip title="Unir">
            <Button
                type="link"
                size="small"
                icon={<ApartmentOutlined style={{ display: "inline-flex" }} rotate={90} />}
                disabled={state.descriptionObjects.selectedObject.length <= 1}
                onClick={mergeObjects}
            />
        </Tooltip>,

        <Tooltip title="Criar Segmento de Contato">
            <Button
                type="link"
                size="small"
                icon={<VerticalAlignMiddleOutlined style={{ display: "inline-flex" }} rotate={90} />}
                disabled={state.descriptionObjects.selectedObject.length != 2}
                onClick={createSegment}
            />
        </Tooltip>
    ]

    const menuSegments = [
        <Tooltip title="Criar Contato">
            <Button type="link" size="small"
                icon={<ClusterOutlined className={`icon ${open ? 'glow' : ''}`} style={{ display: "inline-flex" }} />}
                onClick={() => { createContact(); }}
                disabled={state.descriptionObjects.segment.selectedSegment.length <= 0}
            />
        </Tooltip>,

        <Tooltip title="Pesquisar">
            <Button type="link" size="small"
                icon={<SearchOutlined className={`icon ${openSegmentsSearch ? 'glow' : ''}`} style={{ display: "inline-flex" }} />}
                onClick={() => { setOpenSegmentsSearch(!openSegmentsSearch) }}
            />
        </Tooltip>,

        <Tooltip title="Remover">
            <Button type="link" size="small"
                icon={<DeleteOutlined className={`icon ${deletingSegments ? 'glow' : ''}`} style={{ display: "inline-flex" }} />}
                onClick={() => { setDeletingSegments(!deletingSegments) }}
            />
        </Tooltip>,
    ]

    const menuContacts = [
        <Tooltip title="Pesquisar">
            <Button
                type="link"
                size="small"
                icon={<SearchOutlined className={`icon ${openContactsSearch ? 'glow' : ''}`} style={{ display: "inline-flex" }} />}
                onClick={() => { setOpenContactsSearch(!openContactsSearch) }}
            />
        </Tooltip>,

        <Tooltip title="Remover">
            <Button type="link" size="small"
                icon={<DeleteOutlined className={`icon ${deletingContacts ? 'glow' : ''}`} style={{ display: "inline-flex" }} />}
                onClick={() => { setDeletingContacts(!deletingContacts) }}
            />
        </Tooltip>,
    ]

    return (
        <>
            {contextHolder}
            <Card
                id="objectsCard"
                title={
                    <>
                        <Button
                            size="small"
                            type="text"
                            icon={objectsHide ? <RightOutlined style={{ display: "inline-flex" }} /> : <DownOutlined style={{ display: "inline-flex" }} />}
                            onClick={() => { setObjectsHide(!objectsHide) }}
                        />
                        Objetos Geológicos
                    </>
                }
                extra={viewOnly ? [] : menuObjects}
                size="small"
                bodyStyle={{
                    padding: 0,
                    height: objectsHeight,
                    display: objectsHide ? "none" : "block",
                    overscrollBehavior: "contain"
                }}
                headStyle={{ height: 39 }}
                ref={objectsCardRef}
            >
                <ObjectsTree viewOnly={viewOnly} openSearchBar={openSearchBar} deletingObjects={deletingObjects} height={objectsHeight} />
            </Card>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#DCDCDC',
                    height: dividerHeight,
                    position: 'relative',
                    zIndex:  10000,
                }}
                // onMouseDown={(e) => handleMouseDown(e, "divider1")}
            >
                {/* <SmallDashOutlined style={{ fontSize: '8px', transform: 'scale(3, 1)' }} /> */}
            </div>

            <Card
                id="segmentCard"
                title={
                    <>
                        <Button
                            size="small"
                            type="text"
                            icon={segmentsHide ? <RightOutlined style={{ display: "inline-flex" }} /> : <DownOutlined style={{ display: "inline-flex" }} />}
                            onClick={() => { setSegmentsHide(!segmentsHide) }}
                        />
                        Segmentos de Contatos
                    </>
                }
                size="small"
                extra={viewOnly ? [] : menuSegments}
                bodyStyle={{
                    padding: 0,
                    height: segmentsHeight,
                    display: segmentsHide ? "none" : "block",
                    overscrollBehavior: "contain"
                }}
                headStyle={{ height: 39 }}
                ref={segmentsCardRef}
            >
                <SegmentsForm viewOnly={viewOnly} openSegmentsSearch={openSegmentsSearch} deletingSegments={deletingSegments} height={segmentsHeight} />
            </Card>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#DCDCDC',
                    height: dividerHeight,
                    position: 'relative',
                    zIndex:  10000,
                }}
                // onMouseDown={(e) => handleMouseDown(e, "divider2")}
            >
                {/* <SmallDashOutlined style={{ fontSize: '8px', transform: 'scale(3, 1)' }} /> */}
            </div>

            <Card
                id="contactsCard"
                title={
                    <>
                        <Button
                            size="small"
                            type="text"
                            icon={contactsHide ? <RightOutlined style={{ display: "inline-flex" }} /> : <DownOutlined style={{ display: "inline-flex" }} />}
                            onClick={() => { setContactsHide(!contactsHide) }}
                        />
                        Contatos
                    </>
                }
                size="small"
                extra={viewOnly ? [] : menuContacts}
                bodyStyle={{
                    padding: 0,
                    height: contactsHeigth,
                    display: contactsHide ? "none" : "block",
                    overscrollBehavior: "contain"
                }}
                headStyle={{ height: 39 }}
                ref={contactsCardRef}
            >
                <ContactsForm viewOnly={viewOnly} height={contactsHeigth} openSearchBar={openContactsSearch} deletingContacts={deletingContacts} />
            </Card>

            {open && <AddObjectModal setOpen={setOpen} parentRef={parentRef} mergeRef={mergeRef} /> }
        </>
    );
}

export default GeoObjectForm;