import { React, useContext, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import logoInf from '../../assets/logoInf-wbg.png';
import logoPetrobras from '../../assets/logoPetrobras-wbg.png';
import searchThumb from '../../assets/busca.png';
import newItemThumb from '../../assets/cadastro.png';
import reportsThumb from '../../assets/relatorio.png';
import simulatorThumb from '../../assets/simulador.png';
import { Col, Row, Button, Typography, Image, Card } from 'antd';
import Container from '../Container';
import { store, initialState } from '../Store';
import { setDescriptionInformationAction, setDescriptionObjectsAction } from '../Store/actions';
import './Home.css';

const { Title } = Typography;
const { Meta } = Card;

function Home() {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;

    const [user, setUser] = useState(null);

    useEffect(() => {
        // Retrieve the user from localStorage
        const storedUser = localStorage.getItem('user');

        // If the user exists in localStorage, update the state
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const bodyStyles = {
        background: "radial-gradient(circle, white, #7ea881)",
        margin: "0",
        height: "100vh"
        /* Add any other styles you want for the body element */
    };

    return (
        <div style={bodyStyles}>
            <Container>
                <br />

                <div className='title'>
                    <Title align="middle" level={4} strong>Olá {user ? (user.username) : (<></>)}, seja bem-vindo(a) ao Sistema de Analogia de Geometria e Arquitetura (SAGA - V.1)</Title>
                </div>

                <Row align="middle" justify="center" className='links'>
                    <Col span={8} style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1 }}>
                        <Link to="/description">
                            <Card
                                hoverable
                                style={{
                                    width: 250,
                                }}
                                cover={<Image src={searchThumb} alt="Busca" preview={false} />}
                            >
                                <Meta title="Busca de Análogos" description="Busca por descrições na base de dados com auxílio de mapa e gráficos" />

                            </Card>
                        </Link>
                    </Col>
                    <Col span={8} style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1 }}>
                        <Link
                            onClick={() => {
                                sessionStorage.newSession = "true";
                                dispatch(setDescriptionInformationAction(initialState.descriptionInformation));
                                dispatch(setDescriptionObjectsAction(initialState.descriptionObjects));
                            }}
                            to="/cdescription"
                        >
                            <Card
                                hoverable
                                style={{
                                    width: 250,
                                }}
                                cover={<Image src={newItemThumb} alt="Cadastro" preview={false} />}
                            >
                                <Meta title="Cadastro de Análogos" description="Inclui na base de dados descrições de afloramentos, subsuperfícies, sistema recentes e modelos" />

                            </Card>
                        </Link>
                    </Col>
                    <Col span={8} style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1 }}>
                        <a href='http://geoarchbr.inf.ufrgs.br/'>
                            <Card
                                hoverable
                                style={{
                                    width: 250,
                                }}
                                cover={<Image src={simulatorThumb} alt="Cadastro" preview={false} />}
                            >
                                <Meta title="GeoArchBR" description="Específica canais turbidíticos e faz sua simulação, além de poder visualizá-la porsteriormente" />

                            </Card>
                        </a>
                    </Col>
                </Row>
                <Row align="middle" className='home-footer' justify="center">
                    <Col span={6} >
                        <Image src={logoPetrobras} alt="logoPetrobras" width={300} preview={false} />
                    </Col>
                    <Col span={6} offset={4}>
                        <Image src={logoInf} alt="logoInf" width={100} preview={false} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Home;
