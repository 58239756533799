import React from 'react';
import PropTypes from 'prop-types';

function Card(props) {
    var noClass = props.noClass;
    var defaultClass;

    if (noClass)
        defaultClass = "";
    else
        defaultClass = "card-body";
    
    var bodyClass = defaultClass + " " + props.bodyStyle;
    var id = props.id;

    return (
        <div class="card h-100">
            <div class={defaultClass}>
                <h3 class="card-title">{props.title}</h3>
                {props.id ?

                    <div class={bodyClass} id={id}>
                        {props.children}
                    </div> :

                    <div class={bodyClass} >
                        {props.children}
                    </div>
                }
            </div>
        </div>
    );
}

Card.propTypes = {
    title: PropTypes.string,
    bodyClass: PropTypes.string,
    id: PropTypes.string,
    noClass: PropTypes.bool,
    children: PropTypes.node.isRequired,
};
Card.defaultProps = {
    title: '',
    bodyClass: '',
    noClass: false,
};

export function CardColumn(props) {
    var bodyClass = "card-body " + props.bodyStyle

    return (
        <div class="card">
            <h3 class="card-title">{props.title}</h3>
            <div class={bodyClass}>
                {props.children}
            </div>
        </div>
    );
}

CardColumn.propTypes = {
    title: PropTypes.string,
    bodyClass: PropTypes.string,
    children: PropTypes.node.isRequired,
};
CardColumn.defaultProps = {
    title: '',
    bodyClass: '',
};

export default Card;