import React from 'react';
import { Map } from '../../Maps/index';
import { Breadcrumb, BreadcrumbItemDesactivated, BreadcrumbItemActivated } from '../../Breadcrumb';
import Card from '../../Card';
import { depositionIntervalData } from './data.js'

function CreateOccurrence() {
    return (
        <>
            <div class="container-fluid">

                <Breadcrumb>
                    <BreadcrumbItemDesactivated href="/description">Ocorrência</BreadcrumbItemDesactivated>
                    <BreadcrumbItemActivated>Cadastrar Ocorrência</BreadcrumbItemActivated>
                </Breadcrumb>

                <form action="/cdescription" method="get">
                    <div class="d-grid gap-2">
                        <Card>
                            <div class="row mb-2">
                                <label for="occurenceName" class="col-sm-2 col-form-label">Nome da ocorrência</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" name="occurenceName" />
                                </div>
                            </div>

                            <div class="row mb-2">
                                <label for="author" class="col-sm-2 col-form-label" required>Autor *</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" name="author" />
                                </div>
                            </div>
                        </Card>

                        <Card title="Localização geográfica">
                            <div class="row mb-2">
                                <div class="col-6">
                                    <form class="d-flex">
                                        <input class="form-control me-2" type="search" placeholder="Busca coordenada geográfica (LatiLong)" aria-label="Search" />
                                        <button class="btn btn-outline-success" type="submit">Busca</button>
                                    </form>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <Map
                                    localX="-29.9189"
                                    localY="-51.1781"
                                    cityName="Canoas"
                                    occurenceName="Ocorrencia do bairro niterói"
                                />
                            </div>

                            <br />

                            <div class="row mb-2">

                                <div class="col-2">
                                    <div class="row mb-1">
                                        <div class="col">
                                            <input type="text" placeholder="Latitude" class="form-control" name="coordLat" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-2">
                                    <div class="row mb-1">
                                        <div class="col">
                                            <input type="text" placeholder="Longitude" class="form-control" name="coordLong" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-2">

                                <div class="col-2">
                                    <div class="row mb-1">
                                        <div class="col">
                                            <input type="text" placeholder="País" class="form-control" name="country" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-2">
                                    <div class="row mb-1">
                                        <div class="col">
                                            <input type="text" placeholder="Estado" class="form-control" name="state" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-2">
                                    <div class="row mb-1">
                                        <div class="col">
                                            <input type="text" placeholder="Município" class="form-control" name="city" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row mb-2">

                                <div class="col-2" >
                                    <div class="row mb-1" s>
                                        <label for="type" class="col-form-label" >Tipos</label>
                                    </div>
                                </div>

                                <div class="col-2" >
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" name="outcrop" value="afloramento" />
                                        <label class="form-check-label" for="outcrop">
                                            Afloramento
                                        </label>
                                    </div>
                                </div>

                                <div class="col-2" >
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" name="subsuperficie" value="subsuperficie" />
                                        <label class="form-check-label" for="subsuperficie">
                                            Subsuperfície
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </Card>

                        <Card title="Contexto geológico">
                            <div class="row mb-2">

                                <div class="col-2" >
                                    <div class="row mb-1" s>
                                        <label for="coordLat" class="col-form-label" >Intervalo de deposição *</label>
                                    </div>
                                </div>

                            </div>


                            <div class="row mb-2">
                                <label for="watershed" class="col-sm-2 col-form-label">Bacia *</label>
                                <div class="col-sm-4">
                                    <select class="form-select" name="watershed">
                                        <option disabled selected>Selecione a bacia</option>
                                        <option value="1">Opção 1</option>
                                        <option value="2">Opção 2</option>
                                        <option value="3">Opção 3</option>
                                    </select>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <label for="lithostratigraphy" class="col-sm-2 col-form-label">Litoestratigrafia</label>
                                <div class="col-sm-4">
                                    <select name="lithostratigraphy" class="form-select" aria-label="Default select example">
                                        <option>Grupo</option>
                                        <option>Formação</option>
                                        <option>Membro</option>
                                    </select>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <label for="depositionalEnvironment" class="col-sm-2 col-form-label">Ambiente deposicional predominante</label>
                                <div class="col-sm-4">
                                    <select name="depositionalEnvironment" class="form-select">
                                     <option disabled selected>Selecione o ambiente deposicional</option>
                                        <option value="1"> Deltaico </option>
                                        <option value="2"> Eólico  </option>
                                        <option value="3"> Estuarino  </option>
                                        <option value="4"> Fluvial </option>
                                        <option value="5"> Glacial </option>
                                        <option value="6"> Ilha de  barreira e planície costeira </option>
                                        <option value="7"> Lacustre </option>
                                        <option value="8"> Leque aluvial </option>
                                        <option value="9"> Leque submarino </option>
                                        <option value="10"> Planícies de marés </option>
                                        <option value="11"> Plataforma continental </option>
                                        <option value="12"> Declive continental </option>
                                    </select>
                                </div>
                            </div>
                        </Card>

                        <Card title="Imagem">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <input class="form-control" type="file" id="formImgDesc" multiple />
                                </div>
                            </div>
                        </Card>

                        <div class="row">
                            <div class="col-sm-4">
                                <input class="btn btn-secondary" type="submit" value="Salvar rascunho" />
                            </div>
                            <div class="col-sm-4">
                                <input class="btn btn-danger" type="reset" value="Limpar campos" />
                            </div>
                            <div class="col-sm-4">
                                <input class="btn btn-primary" type="submit" value="Salvar Ocorrência" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}


export default CreateOccurrence;