import React, { useState } from "react";
import { Form } from 'antd'
import "./index.css";

const FloatLabel = (props) => {
    const [focus, setFocus] = useState(false);
    let { name, children, label, placeholder, required, forceOccupied, ...formItemProps } = props;
    if (!placeholder) { placeholder = label }

    const isOccupied = (value) => forceOccupied || focus || (value && value.length !== 0) || parseInt(value) === 0;
    const labelClass = (value) => isOccupied(value) ? "label as-label" : "label as-placeholder";

    return (
        <>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues[name] !== currentValues[name]}
            >
                {({ getFieldValue }) =>
                    <div
                        className="float-label"
                        onBlur={() => setFocus(false)}
                        onFocus={() => setFocus(true)}
                    >
                        <Form.Item name={name} {...formItemProps}>
                            {children}  
                        </Form.Item>
                        <label className={labelClass(getFieldValue(name))}>  
                            {isOccupied(getFieldValue(name)) ? label : placeholder}  
                        </label>
                    </div>
                }
            </Form.Item>  
        </>
    );
};

export default FloatLabel;