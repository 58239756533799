export const ObjectTypes = {
    UNIT: 'Unidade',
    ARCH_ELEMENT: 'ElemArquitetural',
    FACIES_ASSOCIATION: 'AssocFacies'
}

export const ScaleTypes = {
    COMPLEX: 'Complexo',
    COMPOUND: 'Composto',
    INDIVIDUAL: 'Indivíduo',
}

export const ArchitecturalElementTypes = {
    CHANNEL: 'Canal',
    LOBE_SPRAWL: 'Lobo/Espraiamento',
    EXTRAVASATION: 'Extravasamento',
    MASS_TRANSPORT: 'Transporte de massa',
    CONDENSED_SECTION: 'Seção condensada',
    UNDEFINED: 'Não definido',
}