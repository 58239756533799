import { React, useEffect, useState, useContext } from 'react';
import { store } from '../../Store';
import { setDescriptionInformationAction, setDescriptionObjectsAction } from '../../Store/actions';
import { Link as LinkRouter } from 'react-router-dom';
// import MapInformation from '../../MapInformation';
import { useParams } from "react-router-dom";
import CreateDescription from './../CreateDescription';
import DescriptionObjects from './../CreateDescription/DescriptionObjects';
import { Result, Spin, Breadcrumb, Space, Layout, Card, Typography, Button } from 'antd'
import { LoadingOutlined, EditOutlined } from '@ant-design/icons';
import { useAxiosGet } from '../../../hooks/useAxios';
import "./index.css"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Content } = Layout;
const { Link } = Typography;

function ReadDescription() {
    const { descriptionId } = useParams();
    const globalState = useContext(store);
    const { dispatch } = globalState;
    const [activeTabKey2, setActiveTabKey2] = useState('descriptionInformation');
    const { data: descriptionsData, error: descriptionsDataError, loaded: descriptionsDataLoaded } = useAxiosGet("http://api-geoserver.inf.ufrgs.br/description/query");

    useEffect(() => {
        if (descriptionsData && (descriptionId < descriptionsData.length)) {
            descriptionsData[descriptionId].geoDescriptionStored.descriptionObjects.box = { update: 0 };
            dispatch(setDescriptionInformationAction(descriptionsData[descriptionId].geoDescriptionStored.descriptionInformation));
            dispatch(setDescriptionObjectsAction(descriptionsData[descriptionId].geoDescriptionStored.descriptionObjects));
        }
    }, [descriptionsData]);

    const onTab2Change = (key) => {
        setActiveTabKey2(key);
    };

    const tabListNoTitle = [
        {
            key: 'descriptionInformation',
            tab: 'Descrição',
        },
        {
            key: 'descriptionObjects',
            tab: 'Objetos',
        },
    ];

    const contentListNoTitle = {
        descriptionInformation: <CreateDescription viewOnly />,
        descriptionObjects: <DescriptionObjects viewOnly />,
    };

    return (
        <Content style={{ padding: '0 30px' }}>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">
                        <Link>Home </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/description">
                        <Link>Busca de Análogos</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/description">
                        Detalhes do Análogo
                    </Breadcrumb.Item>
                </Breadcrumb>

                {!descriptionsDataLoaded
                    ? <Spin indicator={antIcon} className="centered" />
                    : (descriptionsData && (descriptionId < descriptionsData.length))
                        ?
                        <Card
                            title="Informações da Descrição"
                            style={{ width: '100%' }}
                            tabList={tabListNoTitle}
                            activeTabKey={activeTabKey2}
                            tabBarExtraContent={
                                <LinkRouter to="/cdescription">
                                    <Button type="primary" icon={<EditOutlined style={{ display: 'inline-flex' }} />}>
                                        Editar
                                    </Button>
                                </LinkRouter>
                            }
                            onTabChange={onTab2Change}
                        >
                            {contentListNoTitle[activeTabKey2]}
                        </Card>
                        :
                        <Result
                            status="404"
                            title="404"
                            subTitle="Desculpe, a página que você visitou não existe."
                            extra={
                                <LinkRouter to="/">
                                    <Button type="primary">Voltar</Button>
                                </LinkRouter>
                            }
                        />
                }
            </Space>
        </Content>

    );
}


export default ReadDescription;