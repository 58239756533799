import React from 'react';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from 'react-icons/md';
import { Breadcrumb, BreadcrumbItemActivated, BreadcrumbItemDesactivated } from '../../Breadcrumb';

function Drafts() {
    return (
        <>
            <div class="container-fluid d-grid gap-2">

                <Breadcrumb>
                    <BreadcrumbItemDesactivated href="/description">Home</BreadcrumbItemDesactivated>
                    <BreadcrumbItemActivated>Rascunhos</BreadcrumbItemActivated>
                </Breadcrumb>
                
                <div class="row justify-content-between">
                    <div class="col-6">
                    <form action="#" method="get" class="d-flex">
                            <input class="form-control me-2" type="search" placeholder="Busca por rascunhos" name="Search" />
                            <button class="btn btn-outline-success" type="submit">Busca </button>
                        </form>
                    </div>
                    <div class="col-3">
                        <Link onClick={() => { sessionStorage.newSession = "true";}} to="/cdescription">
                            <button type="button" class="btn btn-primary" >Cadastrar Descrição</button>
                        </Link>
                    </div>
                </div>

                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Autor</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Tipo</th>
                            <th scope="col">Ação </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Mark</td>
                            <td>Desc tal</td>
                            <td>Afloramento</td>
                            <div class="col btn-toolbar">
                                <td><button type="button" class="btn btn-warning mx-2"><MdIcons.MdModeEdit /></button></td>
                                <td><button type="button" class="btn btn-danger mx-2"><FaIcons.FaTrash /></button></td>
                            </div>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Jacob</td>
                            <td>Desc teste</td>
                            <td>Afloramento</td>
                            <div class="col btn-toolbar">
                                <td><button type="button" class="btn btn-warning mx-2"><MdIcons.MdModeEdit /></button></td>
                                <td><button type="button" class="btn btn-danger mx-2"><FaIcons.FaTrash /></button></td>
                            </div>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Larry the Bird</td>
                            <td>Desc testando</td>
                            <td>Afloramento</td>
                            <div class="col btn-toolbar">
                                <td><button type="button" class="btn btn-warning mx-2"><MdIcons.MdModeEdit /></button></td>
                                <td><button type="button" class="btn btn-danger mx-2"><FaIcons.FaTrash /></button></td>
                            </div>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}


export default Drafts;