export function getFileFromCache(state) {
    if (state.descriptionObjects.file?.dataUrl) {
        const { filename, dataUrl } = state.descriptionObjects.file;
        const fileString = decodeURIComponent(atob(dataUrl.split(',')[1]).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('')); // b64DecodeUnicode
        const fileBlob = new File([fileString], filename, { type: 'image/svg+xml' });
        return {
            uid: Date.now(),
            name: filename,
            status: 'done',
            originFileObj: fileBlob,
        };
    } else {
        return undefined;
    }
}

export function findObj(data, key) {
    const stack = data.slice();

    while (stack.length > 0) {
        const current = stack.pop();
        if (current.key === key)
            return current;
        else if (current.children && current.children.length > 0)
            stack.push(...current.children);
    }

    return [];
}

export function updateSelectedKeys(currentSelecteds, key, ctrlKey) {
    if (!key) return currentSelecteds;

    const keyIncluded = currentSelecteds.includes(key);
    let newSelecteds;

    if (ctrlKey) {
        if (keyIncluded)
            newSelecteds = currentSelecteds.filter((_key) => _key !== key);
        else
            newSelecteds = [...currentSelecteds, key];
    } else {
        if (keyIncluded && currentSelecteds.length == 1)
            newSelecteds = [];
        else
            newSelecteds = [key];
    }

    return newSelecteds;
}
