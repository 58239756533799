export const actions = {
    SET_DESCRIPTION_INFORMATION: "SET_DESCRIPTION_INFORMATION",
    SET_DESCRIPTION_OBJECTS: "SET_DESCRIPTION_OBJECTS",
    ADD_DESCRIPTION_OBJECT: "ADD_DESCRIPTION_OBJECT",
    UPDATE_OBJECT_STRUCTURE: "UPDATE_OBJECT_STRUCTURE",
    SET_SELECTED_OBJECT: "SET_SELECTED_OBJECT",
    SET_SELECTED_OBJECT_PROPERTIES: "SET_SELECTED_OBJECT_PROPERTIES",
    DELETE_DESCRIPTION_OBJECT: "DELETE_DESCRIPTION_OBJECT",
    SET_OBJECT_PROPERTIES: "SET_OBJECT_PROPERTIES",
    UPDATE_DESCRIPTION_OBJECTS_FEEDBACK: "UPDATE_DESCRIPTION_OBJECTS_FEEDBACK",
    SELECTING_SEGMENT: "SELECTING_SEGMENT",
    CHECKED_OBJECTS_SEGMENT: "CHECKED_OBJECTS_SEGMENT",
    UPDATE_SEGMENT: "UPDATE_SEGMENT",
    UPDATE_CONTACTS: "UPDATE_CONTACTS",
    SET_SELECTED_SEGMENT: "SET_SELECTED_SEGMENT",
    SET_SELECTED_CONTACT: "SET_SELECTED_CONTACT",
    SET_FILE: "SET_FILE",
    SET_DESCRIPTION_DATA_RETRIEVAL: "SET_DESCRIPTION_DATA_RETRIEVAL",
    DELETE_DESCRIPTION_CHARTS: "DELETE_DESCRIPTION_CHARTS",
    UPDATE_DESCRIPTION_CHARTS: "UPDATE_DESCRIPTION_CHARTS",
    SET_DESCRIPTION_CHARTS: "SET_DESCRIPTION_CHARTS",
    SET_EDITING_DESCRIPTION: "SET_EDITING_DESCRIPTION",
    UPDATE_ARCHITECTURAL_TYPE_COLORS: "UPDATE_ARCHITECTURAL_TYPE_COLORS"
}

export const setEditingDescriptionAction = (isEditing) => ({
    type: actions.SET_EDITING_DESCRIPTION,
    value: isEditing,
});

export const setDescriptionInformationAction = (descriptionInformation) => ({
    type: actions.SET_DESCRIPTION_INFORMATION,
    value: descriptionInformation,
});

export const setDescriptionObjectsAction = (descriptionObjects) => ({
    type: actions.SET_DESCRIPTION_OBJECTS,
    value: descriptionObjects,
});

export const addDescriptionObject = (descriptionObject) => ({
    type: actions.ADD_DESCRIPTION_OBJECT,
    value: descriptionObject,
});

export const updateObjectStructure = (objectStructure) => ({
    type: actions.UPDATE_OBJECT_STRUCTURE,
    value: objectStructure,
});

export const setSelectedObject = (selectedObject) => ({
    type: actions.SET_SELECTED_OBJECT,
    value: selectedObject,
});

export const deleteDescriptionObject = (updatedInfo) => ({
    type: actions.DELETE_DESCRIPTION_OBJECT,
    value: updatedInfo,
});

export const setObjectProperties = (objectProperties) => ({
    type: actions.SET_OBJECT_PROPERTIES,
    value: objectProperties,
});

export const setSelectedObjectProperties = (objectProperties) => ({
    type: actions.SET_SELECTED_OBJECT_PROPERTIES,
    value: objectProperties,
});

export const updateDescriptionObjectsFeedback = (descriptionObjectsFeedback) => ({
    type: actions.UPDATE_DESCRIPTION_OBJECTS_FEEDBACK,
    value: descriptionObjectsFeedback,
});


export const setSelectingSegment = (selectingSegment) => ({
    type: actions.SELECTING_SEGMENT,
    value: selectingSegment,
});


export const setCheckedsObjectsSegments= (checkedsObjectsSegments) => ({
    type: actions.CHECKED_OBJECTS_SEGMENT,
    value: checkedsObjectsSegments,
});

export const updateSegments= (segment) => ({
    type: actions.UPDATE_SEGMENT,
    value: segment,
});

export const updateContacts = (segment) => ({
    type: actions.UPDATE_CONTACTS,
    value: segment,
});

export const setSelectedSegment = (selectedSegment) => ({
    type: actions.SET_SELECTED_SEGMENT,
    value: selectedSegment,
});

export const setSelectedContact = (selectedContact) => ({
    type: actions.SET_SELECTED_CONTACT,
    value: selectedContact,
});

export const setFile = (file) => ({
    type: actions.SET_FILE,
    value: file,
});

export const setDescriptionDataRetrieval = (descriptionDataRetrieval) => ({
    type: actions.SET_DESCRIPTION_DATA_RETRIEVAL,
    value: descriptionDataRetrieval,
});

export const updateDescriptionDataCharts = (updateDescriptionDataCharts) => ({
    type: actions.UPDATE_DESCRIPTION_CHARTS,
    value: updateDescriptionDataCharts,
});

export const setDescriptionDataCharts = (descriptionDataCharts) => ({
    type: actions.SET_DESCRIPTION_CHARTS,
    value: descriptionDataCharts,
});

export const deleteDescriptionCharts = (indexDescriptionDataCharts) => ({
    type: actions.DELETE_DESCRIPTION_CHARTS,
    value: indexDescriptionDataCharts,
});

export const updateArchitecturalTypeColors = (updateArchitecturalTypeColors) => ({
    type: actions.UPDATE_ARCHITECTURAL_TYPE_COLORS,
    value: updateArchitecturalTypeColors,
});

