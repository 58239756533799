import React from 'react';
import * as FaIcons from 'react-icons/fa';
import { Breadcrumb, BreadcrumbItemActivated } from '../Breadcrumb';
import Card from './../Card';

function Notifications() {
    return (
        <>
            <div class="container d-grid gap-2">

                <Card>

                <Breadcrumb>
                    <BreadcrumbItemActivated>Notificações</BreadcrumbItemActivated>
                </Breadcrumb>

                <Card>
                    <h5 class="card-title">Tipo de notificação</h5>
                    <p class="card-text">Texto sobre a notificação.</p>
                    <a href="/" class="btn btn-info">Clique para conferir</a>
                    <a href="/" class="btn btn-danger">Clique para me apagar :(</a>
                </Card>

                <Card>
                    <h5 class="card-title">Mudança em ocorrência monitorada</h5>
                    <p class="card-text">Agostinho Carrara adicionou uma nova descrição na ocorrência de Caçapava do Sul.</p>
                    <a href="/" class="btn btn-info"><FaIcons.FaLocationArrow /></a>
                    <a href="/" class="btn btn-danger"><FaIcons.FaTrash /></a>
                </Card>
                </Card>

            </div>
        </>
    );
}


export default Notifications;