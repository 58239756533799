import React, { useState } from 'react';
import { message } from 'antd';
import PetrobrasLogo from '../../assets/petrobras-8.svg';
import AuthService from '../../services/AuthService';
import './Login.css';

const Login = ({ setToken }) => {
    const [credentials, setCredentials] = useState({ username: '', password: '' });

    const onChangeUsername = (e) => {
        setCredentials({ ...credentials, username: e.target.value });
    };

    const onChangePassword = (e) => {
        setCredentials({ ...credentials, password: e.target.value });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const response = await AuthService.login(credentials.username, credentials.password);
        if (response[1] === 401) //error in login
            message.error('Nome de usuário ou Senha inválidos');
        else
            setToken(response[0].access_token);
    };

    return (
        <main className="form-signin text-center">
            <form onSubmit={handleLogin}>
                <img className="mb-4" src={PetrobrasLogo} alt="" width="80" height="80" />
                <h1 className="h3 mb-3 fw-normal">Insira seus dados</h1>

                <div className="form-floating">
                    <input
                        type="text"
                        className="form-control"
                        value={credentials.username}
                        onChange={onChangeUsername}
                        id="floatingInput"
                        placeholder="Nome de Usuário"
                    />
                    <label htmlFor="floatingInput">Nome de usuário</label>
                </div>
                <div className="form-floating">
                    <input
                        type="password"
                        className="form-control"
                        value={credentials.password}
                        onChange={onChangePassword}
                        id="floatingPassword"
                        placeholder="Senha"
                    />
                    <label htmlFor="floatingPassword">Senha</label>
                </div>

                <button className="w-100 btn btn-lg btn-primary" type="submit">
                    Acessar
                </button>
            </form>
        </main>
    );
};

export default Login;