export const chartColors = {
    "Eixo de canal": "#F3E802",
    "Margem de canal": "#3EB8DE",
    "Base de canal": "#E76223",
    "Escorregamento arenoso": "#008B3A", 
    "Escorregamento lamoso": "#ABCB69", 
    "Extravasamento externo arenoso": "#EF9567", 
    "Extravasamento interno arenoso": "#A2D1C0",
    "Extravasamento externo lamoso": "#EF9567", 
    "Extravasamento interno lamoso":"#A2D1C0", 
    "Lamito hemipelagico":"#DDE0E0",
}

export const architecturalTypeColors = {
    "Canal": "#2caffe",
    "Lobo/Espraiamento": "#544fc5",
    "Extravasamento": "#00e272",
    "Transporte de massa": "#fe6a35",
    "Seção condensada": "#6b8abc"
}

export const  scaleTypeMarker = {
    "Complexo": "circle",
    "Composto": "triangle",
    "Indivíduo": "triangle-down"
}