import React, { createContext, useEffect, useReducer } from 'react';
import { act } from 'react-dom/test-utils';
import { actions } from './actions';

import * as FaIcons from 'react-icons/fa';
import * as tbIcons from 'react-icons/tb';

import { ObjectTypes } from "../Description/CreateDescription/DescriptionObjects/types";

import Cryptic from "../../assets/cryptic.svg"
import Erosive from "../..//assets/erosive.svg"
import Faulted from "../../assets/faulted.svg"
import Gradational from "../../assets/gradational.svg"
import LagDeposit from "../../assets/lagDeposit.svg"
import Sharp from "../../assets/sharp.svg"
import Undefined from "../../assets/undefined.png"
import Icon from '@ant-design/icons';
import { initial, pullAll } from 'lodash';
import { architecturalTypeColors } from '../Description/CreateDescription/DescriptionObjects/Properties/chartColors.js'
import { useAxiosGet } from '../../hooks/useAxios';


const { UNIT, ARCH_ELEMENT, FACIES_ASSOCIATION } = ObjectTypes;

const initialState = {
    descriptionInformation: {
        descriptionType: "",
        topDepth: "",
        bottomDepth: "",
        unit: "",
        coordinatesType: "dms",
        latitude: null,
        longitude: null,
        country: "",
        state: "",
        city: "",
        author: "",
        dataDate: "",
        docSrc: [],
        refLink: [],
        geologicalSiteName: "",
        watershed: "",
        fieldName: "",
        lithostratigraphyGroup: "",
        lithostratigraphyFormation: "",
        lithostratigraphyMember: "",
        geochronology: [],
        sedimentaryEnvironment: [],
        depositionalSystem: [],
        additionalInformation: "",
        descriptionInformationFeedback: ""
    },
    descriptionObjects: {
        selectedObject: [],
        nextId: {},
        objects: [],
        box: { update: 0 },
        pit: {},
        descriptionObjectsFeedback: "",
        file: {},
        updatedObjects: 0,
        segment: {
            selectingSegment: null,
            checkedsObjectsSegments: [],
            tree: [],
            nextId: 0,
            selectedSegment: [],
            updated: 0,
        },
        contacts: {
            tree: [],
            nextId: 0,
            selectedContact: [],
            updated: 0,
        }
    },
    descriptionDataRetrieval: {},
    descriptionCharts: { "component": [], "key": [], "option": [] },
    isEditing: false,
    architecturalTypeColors: {
        "Acobertamento":"hsl(0, 70%, 50%)","Acobertamento local":"hsl(11.612903225806452, 70%, 50%)","Acobertamento regional":"hsl(23.225806451612904, 70%, 50%)","Campo de dunas":"hsl(34.83870967741935, 70%, 50%)","Canal":"hsl(46.45161290322581, 70%, 50%)","Canal agradacional":"hsl(58.064516129032256, 70%, 50%)","Canal degradacional":"hsl(69.6774193548387, 70%, 50%)","Canal neutro":"hsl(81.29032258064515, 70%, 50%)","Cunha de contorno":"hsl(92.90322580645162, 70%, 50%)","Dique":"hsl(104.51612903225808, 70%, 50%)","Dique intrusivo":"hsl(116.12903225806451, 70%, 50%)","Domo":"hsl(127.74193548387098, 70%, 50%)","Edifício":"hsl(139.3548387096774, 70%, 50%)","Espraiamento":"hsl(150.96774193548387, 70%, 50%)","Espraiamento capturado":"hsl(162.5806451612903, 70%, 50%)","Espraiamento lateral":"hsl(174.19354838709677, 70%, 50%)","Espraiamento terminal":"hsl(185.80645161290323, 70%, 50%)","Extravasamento":"hsl(197.41935483870967, 70%, 50%)","Extravasamento conectado":"hsl(209.03225806451616, 70%, 50%)","Extravasamento descontectado":"hsl(220.6451612903226, 70%, 50%)","Extravasamento externo conectado":"hsl(232.25806451612902, 70%, 50%)","Extravasamento externo desconectado":"hsl(243.87096774193546, 70%, 50%)","Extravasamento interno conectado":"hsl(255.48387096774195, 70%, 50%)","Extravasamento interno descontectado":"hsl(267.0967741935484, 70%, 50%)","Lençol":"hsl(278.7096774193548, 70%, 50%)","Lobo":"hsl(290.3225806451613, 70%, 50%)","Monte":"hsl(301.93548387096774, 70%, 50%)","Olistrostomas":"hsl(313.5483870967742, 70%, 50%)","Soleira intrusiva":"hsl(325.1612903225806, 70%, 50%)","Transporte de massa":"hsl(336.7741935483871, 70%, 50%)","Zona de transição canal-espraiamento":"hsl(348.38709677419354, 70%, 50%)",
        "Não definido":"hsl(0, 0%, 0%)"
        //        "Canal": "#2caffe",
        //      "Lobo/Espraiamento": "#544fc5",
        //    "Extravasamento": "#00e272",
        //  "Transporte de massa": "#fe6a35",
        //  "Seção condensada": "#6b8abc",
        //  "Não definido": "#333333"
    },
    ontologyInformation: {
        "architecturalElementTypes": {},
        "faciesAssociationTypes": {}
    }
}

const store = createContext(initialState);
const { Provider } = store;

const descriptionReducer = (state, action) => {
    switch (action.type) {
        case actions.SET_EDITING_DESCRIPTION:
            console.log("actions.SET_EDITING_DESCRIPTION", action.value);
            return { ...state, isEditing: action.value };

        case actions.SET_DESCRIPTION_INFORMATION:
            console.log("actions.SET_DESCRIPTION_INFORMATION", action.value);
            return { ...state, descriptionInformation: action.value };

        case actions.SET_DESCRIPTION_OBJECTS:
            // console.log("actions.SET_DESCRIPTION_OBJECTS", action.value);
            return { ...state, descriptionObjects: action.value };

        case actions.ADD_DESCRIPTION_OBJECT:
            console.log("ADD_DESCRIPTION_OBJECT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    nextId: action.value.nextId,
                    objects: action.value.objects
                }
            }

        case actions.UPDATE_OBJECT_STRUCTURE:
        case actions.SET_OBJECT_PROPERTIES:
            console.log("UPDATE_OBJECT_STRUCTURE || SET_OBJECT_PROPERTIES", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    objects: action.value
                }
            }

        case actions.SET_SELECTED_OBJECT:
            console.log("SET_SELECTED_OBJECT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    selectedObject: action.value,
                    segment: {
                        ...state.descriptionObjects.segment,
                        selectedSegment: [],
                    },
                    contacts: {
                        ...state.descriptionObjects.contacts,
                        selectedContact: []
                    }
                }
            }

        case actions.SET_SELECTED_OBJECT_PROPERTIES:
            console.log("SET_SELECTED_OBJECT_PROPERTIES", action.value);;
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    selectedObject: {
                        ...state.descriptionObjects.selectedObject,
                        properties: {
                            ...state.descriptionObjects.selectedObject.properties,
                            width: action.value.width,
                            thickness: action.value.thickness,
                        }
                    }
                }
            }

        case actions.DELETE_DESCRIPTION_OBJECT:
            console.log("DELETE_DESCRIPTION_OBJECT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    objects: action.value.objects,
                    selectedObject: action.value.selectedObject,
                    deletedObject: action.value.deletedObject
                }
            }

        case actions.UPDATE_DESCRIPTION_OBJECTS_FEEDBACK:
            console.log("UPDATE_DESCRIPTION_OBJECTS_FEEDBACK", action.value);;
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    descriptionObjectsFeedback: action.value,
                }
            }

        case actions.SELECTING_SEGMENT:
            console.log("SELECTING_SEGMENT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    segment: {
                        ...state.descriptionObjects.segment,
                        selectingSegment: action.value,
                    }
                }
            }

        case actions.CHECKED_OBJECTS_SEGMENT:
            console.log("CHECKED_OBJECTS_SEGMENT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    segment: {
                        ...state.descriptionObjects.segment,
                        checkedsObjectsSegments: action.value,
                    }
                }
            }

        case actions.UPDATE_SEGMENT:
            console.log("UPDATE_SEGMENT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    segment: {
                        ...state.descriptionObjects.segment,
                        tree: action.value.tree,
                        nextId: action.value.nextId,
                        //id: action.value.nextId,
                    },
                }
            }

        case actions.UPDATE_CONTACTS:
            console.log("UPDATE_CONTACT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    contacts: {
                        ...state.descriptionObjects.contacts,
                        tree: action.value.tree,
                        nextId: action.value.nextId,
                        //id: action.value.nextId,
                    },
                }
            }

        case actions.SET_SELECTED_SEGMENT:
            console.log("SET_SELECTED_SEGMENT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    selectedObject: [],
                    segment: {
                        ...state.descriptionObjects.segment,
                        selectedSegment: action.value,
                    },
                    contacts: {
                        ...state.descriptionObjects.contacts,
                        selectedContact: []
                    }
                }
            }

        case actions.SET_SELECTED_CONTACT:
            console.log("SET_SELECTED_CONTACT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    selectedObject: [],
                    segment: {
                        ...state.descriptionObjects.segment,
                        selectedSegment: [],
                    },
                    contacts: {
                        ...state.descriptionObjects.contacts,
                        selectedContact: action.value,
                    },
                }
            }

        case actions.SET_DESCRIPTION_DATA_RETRIEVAL:
            console.log(action.value);
            return {
                ...state,
                descriptionDataRetrieval: action.value
            }

        case actions.SET_DESCRIPTION_CHARTS:
            console.log(action.value);
            return {
                ...state,
                descriptionCharts: action.value
            }

        case actions.DELETE_DESCRIPTION_CHARTS:
            console.log(action.value);
            return {
                ...state,
                descriptionCharts: deleteDescriptionChartByIndex({ ...state.descriptionCharts }, action.value)
            }

        case actions.UPDATE_DESCRIPTION_CHARTS:
            console.log(action.value);
            return {
                ...state,
                descriptionCharts: {
                    key: [...state.descriptionCharts.key, action.value.key],
                    option: [...state.descriptionCharts.option, action.value.option],
                    component: [...state.descriptionCharts.component, action.value.component]
                }
            }

        case actions.SET_FILE:
            console.log(action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    file: action.value
                }
            }

        case actions.UPDATE_ARCHITECTURAL_TYPE_COLORS:
            console.log(action.value);
            return {
                ...state,
                architecturalTypeColors: action.value
            }

        default:
            return state;
    };
}

const deleteDescriptionChartByIndex = (data, indexToDelete) => {
    return {
        key: data.key.filter((_, index) => index !== indexToDelete),
        option: data.option.filter((_, index) => index !== indexToDelete),
        component: data.component.filter((_, index) => index !== indexToDelete)
    };
}

// Função para gerar uma cor baseada em HSL
const generateColor = (index, total) => {
    const hue = (index / total) * 360; // Distribui os tons uniformemente ao longo da roda de cores (HSL)
    return `hsl(${hue}, 70%, 50%)`; // Saturação e luminosidade fixas para manter contraste
};

// Função para gerar uma paleta de cores baseada no número de classes
const generatePalette = (classes) => {
    const totalClasses = classes.length;
    const preferredLanguages = ["pt-br", "pt"];

    const colorMap = {};

    if (Object.keys(classes).length !== 0) {
        classes.forEach((x, index) => {
            colorMap[x.labels[preferredLanguages.find(lang => x.labels[lang])]] = generateColor(index, totalClasses); // Atribui uma cor para cada classe
        });
    }


    return colorMap;

};

const StateProvider = ({ children }) => {
    const descriptionInfoURL = "http://api-geoserver.inf.ufrgs.br/description/data";
    const { data: architecturalElementData, error: architecturalElementDataError, loaded: architecturalElementDataLoaded } = useAxiosGet(descriptionInfoURL, sessionStorage);
    const urlGeologicalObjects = 'geologicalObjects';
    const urlArchitecturalElement = 'architecturalElement';
    const urlArchitecturalElementType = 'architecturalElementType';
    const urlFaciesAssociation = "faciesAssociation";
    const urlTypeFaciesAssociation = "typeFaciesAssociation";

    function capitalizeFirstLetter(string) {
        return string[0].charAt(0).toUpperCase() + string[0].slice(1);
    }

    function processOntologyList(object) {
        //Sorting by portuguese label
        object.sort((a, b) => {
            const labelA = a.labels["pt-br"] || a.labels["pt"];
            const labelB = b.labels["pt-br"] || b.labels["pt"];

            if (labelA < labelB) return -1;
            if (labelA > labelB) return 1;
            return 0;
        });

        //Convert the first letter to uppercase
        object.forEach(obj => {
            if (obj.labels["pt-br"]) {
                obj.labels["pt-br"] = capitalizeFirstLetter(obj.labels["pt-br"]);
            }

            if (obj.labels["pt"]) {
                obj.labels["pt"] = capitalizeFirstLetter(obj.labels["pt"]);
            }
        });

        return object;
    }

    useEffect(() => {
        if (architecturalElementData != null) {
            let faciesAssociationTypes = architecturalElementData[urlGeologicalObjects][urlFaciesAssociation][urlTypeFaciesAssociation].types;
            let architecturalElementTypes = architecturalElementData[urlGeologicalObjects][urlArchitecturalElement][urlArchitecturalElementType].types;

            faciesAssociationTypes = processOntologyList(faciesAssociationTypes);
            architecturalElementTypes = processOntologyList(architecturalElementTypes);

            //Saving
            localStorage.setItem('ontologyInformation', JSON.stringify(
                {
                    ...state.ontologyInformation,
                    "faciesAssociationTypes": faciesAssociationTypes,
                    "architecturalElementTypes": architecturalElementTypes
                }
            ));

            //Architectural elements colors
            const palette = {...generatePalette(architecturalElementTypes), "Não definido": "hsl(0, 0%, 0%)"};
            localStorage.architecturalTypeColors = JSON.stringify(palette);
        }

    }, [architecturalElementData]);

    const getIconObjects = (type) => {
        switch (type) {
            case UNIT:
                return <tbIcons.TbChartRadar />;
            case FACIES_ASSOCIATION:
                return <FaIcons.FaRegCircle />;
            case ARCH_ELEMENT:
                return <tbIcons.TbChartBubble />;
            default:
                return;
        }
    }

    const getIconSegments = (type) => {
        switch (type) {
            case "Abrupto":
                return Sharp;
            case "Críptico":
                return Cryptic;
            case "De falha":
                return Faulted;
            case "Erosional":
                return Erosive;
            case "Gradacional":
                return Gradational;
            case "Linha de seixos":
                return LagDeposit;
            case "Indefinido":
                return Undefined;
            default:
                return;
        }
    }

    const addIconToObj = (node, index, array) => {
        let new_node = node;

        new_node.icon = getIconObjects(node.geologicalObjectType)

        if (node.hasOwnProperty('children'))
            new_node.children = node.children.map(addIconToObj);

        return new_node;
    }

    const addIconToSegment = (node, index, array) => {
        let new_node = node;
        new_node.icon = <Icon component={() => (<img src={getIconSegments(node.typeSegment)} style={{ padding: 1, width: 40, marginLeft: -13 }} />)} />
        return new_node;
    }

    const [state, dispatch] = useReducer(descriptionReducer, {}, () => {
        const localDescriptionInfo = localStorage.getItem('descriptionInformation');
        const localDescriptionObjects = localStorage.getItem('descriptionObjects');
        const localDescriptionDataRetrieval = localStorage.getItem('descriptionDataRetrieval');
        const localDescriptionCharts = localStorage.getItem('descriptionCharts');
        const localArchitecturalTypeColors = localStorage.getItem('architecturalTypeColors');
        const localOntologyInformation = localStorage.getItem('ontologyInformation');

        if (localDescriptionInfo)
            if (localDescriptionObjects) {
                const aux_descriptionObjects = JSON.parse(localDescriptionObjects);
                const objectsWithIcon = aux_descriptionObjects.objects.map(addIconToObj);
                const segmentWithIcon = aux_descriptionObjects.segment.tree.map(addIconToSegment);
                //const parsedArchitecturalTypeColors = localArchitecturalTypeColors ? JSON.parse(localArchitecturalTypeColors) : initialState.architecturalTypeColors;
                const infos = {
                    descriptionInformation: JSON.parse(localDescriptionInfo),
                    descriptionObjects: {
                        //selectedObject: aux_descriptionObjects.selectedObject,
                        //nextId: aux_descriptionObjects.nextId,
                        //segment: aux_descriptionObjects.segment,
                        ...aux_descriptionObjects,
                        box: { update: 0 },
                        objects: objectsWithIcon,
                        //descriptionObjectsFeedback: aux_descriptionObjects.descriptionObjectsFeedback,
                        segment: {
                            ...aux_descriptionObjects.segment,
                            tree: segmentWithIcon,
                        },
                    },
                    descriptionDataRetrieval: JSON.parse(localDescriptionDataRetrieval),
                    descriptionCharts: JSON.parse(localDescriptionCharts),
                    ontologyInformation: JSON.parse(localOntologyInformation),
                    architecturalTypeColors: JSON.parse(localArchitecturalTypeColors)
                };
                return infos;
            }
            else {
                return initialState
                //return { descriptionInformation: JSON.parse(localDescriptionInfo), descriptionObjects: initialState.descriptionObjects, descriptionDataRetrieval: initialState.descriptionDataRetrieval,  descriptionCharts: initialState.descriptionCharts}
                //return { descriptionInformation: JSON.parse(localDescriptionInfo), descriptionObjects: initialState.descriptionObjects}
            }
        else {
            return initialState;
        }

    });

    useEffect(() => {
        localStorage.setItem('descriptionInformation', JSON.stringify(state.descriptionInformation));
    }, [state.descriptionInformation]);


    useEffect(() => {
        const objectsWithIcon = state.descriptionObjects.objects.map(addIconToObj);
        const segmentWithIcon = state.descriptionObjects.segment.tree.map(addIconToSegment);

        const descriptionObjectsParsed = {
            //selectedObject: state.descriptionObjects.selectedObject,
            //nextId: state.descriptionObjects.nextId,
            //segment: state.descriptionObjects.segment,
            ...state.descriptionObjects,
            objects: objectsWithIcon,
            //descriptionObjectsFeedback: state.descriptionObjects.descriptionObjectsFeedback
            segment: {
                ...state.descriptionObjects.segment,
                tree: segmentWithIcon,
            }
        }

        localStorage.setItem('descriptionObjects', JSON.stringify(descriptionObjectsParsed));
    }, [state.descriptionObjects]);

    useEffect(() => {
        const descriptionObjectsParsed = { ...state.descriptionDataRetrieval }


        localStorage.setItem('descriptionDataRetrieval', JSON.stringify(descriptionObjectsParsed));
    }, [state.descriptionDataRetrieval]);

    useEffect(() => {
        const descriptionObjectsParsed = { ...state.descriptionCharts };

        localStorage.setItem('descriptionCharts', JSON.stringify(descriptionObjectsParsed));
    }, [state.descriptionCharts]);

    useEffect(() => {
        const architecturalTypeColorsPased = { ...state.architecturalTypeColors };

        localStorage.setItem('architecturalTypeColors', JSON.stringify(architecturalTypeColorsPased));
    }, [state.architecturalTypeColors]);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider, initialState }
