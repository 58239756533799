import React, { useEffect, useState, useContext, useMemo } from 'react';
import { store } from '../../../../Store';
import { setObjectProperties } from '../../../../Store/actions';
import { ObjectTypes, ScaleTypes } from "../types";
import { TreeSelect, Popover, Button, Select, Form, Row, Col, Space, Card, InputNumber, Divider, Tree, Popconfirm } from 'antd';
import { TableOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FaciesTable from './FaciesTable';
import { compLithologyData } from './compLithologyData';
import Grainsize from '../../../../../assets/grainsize.jpg';
import Roundness from '../../../../../assets/roundness.jpg';
import Selection from '../../../../../assets//selection.jpg';
import FloatLabel from "../../../../FloatLabel";
import { useAxiosGet } from '../../../../../hooks/useAxios';
import { debounce, union } from 'lodash';

const { UNIT, ARCH_ELEMENT, FACIES_ASSOCIATION } = ObjectTypes;
const { INDIVIDUAL } = ScaleTypes;
const { Option } = Select;


const Properties = (props) => {
    const globalState = useContext(store);
    const { state } = globalState;
    const { viewOnly } = props;
    const [type, setType] = useState()
    const objects = state.descriptionObjects.objects;

    useEffect(() => {
        if (state.descriptionObjects.selectedObject.length === 1) {
            const obj = findObj(objects, state.descriptionObjects.selectedObject[0]);
            setType(obj?.geologicalObjectType || undefined);
        } else {
            setType(undefined);
        }
    }, [state.descriptionObjects.selectedObject])

    return (
        <>
            {(type === UNIT || type === ARCH_ELEMENT) &&
                <div>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Geometry viewOnly={viewOnly} />
                        </Col>
                        <Col span={12}>
                            <Dimension viewOnly={viewOnly} />
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                        <Col span={24}>
                            <ObjectProperties viewOnly={viewOnly} />
                        </Col>
                    </Row>
                </div>
            }
            {type === FACIES_ASSOCIATION &&
                <div>
                    <Row gutter={16}>
                        <Col span={24}>
                            <ObjectFaciesAssoc viewOnly={viewOnly} />
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                        <Col span={24}>
                            <ObjectProperties viewOnly={viewOnly} />
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export default Properties;

const Geometry = (props) => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const [form] = Form.useForm();
    const { viewOnly } = props;

    const objects = state.descriptionObjects.objects;

    const descriptionInfoURL = "http://api-geoserver.inf.ufrgs.br/description/data";
    const urlGeologicalObjects = 'geologicalObjects';
    const urlArchitecturalElement = 'architecturalElement';
    const urlBaseGeometry = 'bottomGeometry';
    const urlGeometryType = 'typeGeometry';
    const urlTopGeometry = 'topGeometry';

    const { data: architecturalElementData, error: architecturalElementDataError, loaded: architecturalElementDataLoaded } = useAxiosGet(descriptionInfoURL, sessionStorage);

    const architecturalElement = useMemo(() => {
        const generateList = (data) => {
            let tempList = [];
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                let newNode = {}
                newNode.title = node.labels.pt[0]
                newNode.value = node.iri;
                if (node.types)
                    newNode.children = generateList(node.types)
                else
                    newNode.children = [];
                tempList.push(newNode);
            }
            return tempList;
        };

        let parsedArchitecturalElementFields = {};

        if (architecturalElementData) {
            let baseGeometry = architecturalElementData[urlGeologicalObjects][urlArchitecturalElement][urlBaseGeometry].types;
            parsedArchitecturalElementFields.baseGeometry = generateList(baseGeometry);

            let geometryType = architecturalElementData[urlGeologicalObjects][urlArchitecturalElement][urlGeometryType].types;
            parsedArchitecturalElementFields.geometryType = generateList(geometryType);

            let topGeometry = architecturalElementData[urlGeologicalObjects][urlArchitecturalElement][urlTopGeometry].types;
            parsedArchitecturalElementFields.topGeometry = generateList(topGeometry);

            return parsedArchitecturalElementFields;
        }

    }, [architecturalElementData]);

    useEffect(() => {
        const properties = findObj(objects, state.descriptionObjects.selectedObject[0])?.properties;
        form.setFieldsValue({
            typeGeometry: (properties && properties.typeGeometry) ? properties.typeGeometry : "",
            bottomGeometry: (properties && properties.bottomGeometry) ? properties.bottomGeometry : "",
            topGeometry: (properties && properties.topGeometry) ? properties.topGeometry : ""
        })
    }, [state.descriptionObjects.selectedObject])

    const dispatchProperties = (changedValue) => {
        const [name, value] = Object.entries(changedValue)[0];
        dispatch(
            setObjectProperties(
                updateObj(
                    state.descriptionObjects.selectedObject[0],
                    state.descriptionObjects.objects,
                    name,
                    value
                )
            )
        );
    }

    return (
        <Card title="Geometria" style={{ height: '100%' }}>
            <Form
                name="geometry"
                form={form}
                layout="vertical"
                onValuesChange={debounce(dispatchProperties, 500)}
                disabled={viewOnly}
            >
                <FloatLabel label="Tipo" name="typeGeometry" rules={[{ required: true }]}>
                    <Select style={{ width: "100%" }}>
                        {!architecturalElementDataLoaded ? (
                            <Option disabled key="loading" value="loading">Carregando...</Option>
                        ) : (
                            architecturalElement.geometryType.map(geometryType => (
                                <Option key={geometryType.value} value={geometryType.value}>
                                    {geometryType.title}
                                </Option>
                            ))
                        )}
                    </Select>
                </FloatLabel>
                <FloatLabel name="bottomGeometry" label="Base">
                    <Select style={{ width: "100%" }}>
                        {!architecturalElementDataLoaded ? (
                            <Option disabled key="loading" value="loading">Carregando...</Option>
                        ) : (
                            architecturalElement.baseGeometry.map(baseGeometry => (
                                <Option key={baseGeometry.value} value={baseGeometry.value}>
                                    {baseGeometry.title}
                                </Option>
                            ))
                        )}
                    </Select>
                </FloatLabel>
                <FloatLabel name="topGeometry" label="Topo">
                    <Select style={{ width: "100%" }}>
                        {!architecturalElementDataLoaded ? (
                            <Option disabled key="loading" value="loading">Carregando...</Option>
                        ) : (
                            architecturalElement.topGeometry.map(topGeometry => (
                                <Option key={topGeometry.value} value={topGeometry.value}>
                                    {topGeometry.title}
                                </Option>
                            ))
                        )}
                    </Select>
                </FloatLabel>
            </Form>
        </Card>
    )
}

const Dimension = (props) => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const [form] = Form.useForm();
    const { viewOnly } = props;
    const objects = state.descriptionObjects.objects;

    useEffect(() => {
        const properties = findObj(objects, state.descriptionObjects.selectedObject[0])?.properties;
        form.setFieldsValue({
            width: (properties && properties.width) ? properties.width : "",
            thickness: (properties && properties.thickness) ? properties.thickness : "",
            area: (properties && properties.area) ? properties.area : "",
        })
    }, [state.descriptionObjects.selectedObject])

    const dispatchProperties = (changedValue) => {
        const [name, value] = Object.entries(changedValue)[0]
        dispatch(
            setObjectProperties(
                updateObj(
                    state.descriptionObjects.selectedObject[0],
                    state.descriptionObjects.objects,
                    name,
                    value
                )
            )
        );
    }

    return (
        <Card title="Dimensão" style={{ height: '100%' }}>
            <Form
                name="dimension"
                form={form}
                layout="vertical"
                onValuesChange={debounce(dispatchProperties, 500)}
                disabled={viewOnly}
            >
                <FloatLabel label="Largura" name="width">
                    <InputNumber style={{ width: '100%' }} precision={2} />
                </FloatLabel>
                <FloatLabel label="Espessura" name="thickness">
                    <InputNumber style={{ width: '100%' }} precision={2} />
                </FloatLabel>
                <FloatLabel label="Área" name="area">
                    <InputNumber style={{ width: '100%' }} precision={2} />
                </FloatLabel>

            </Form>
        </Card>
    )
}


const ObjectFaciesAssoc = (props) => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const [form] = Form.useForm();
    const { viewOnly } = props;
    const [facies, setFacies] = useState({
        data: [],
        nextKey: 0,
        update: 0
    });
    const [typeFaciesAssociation, setTypeFaciesAssociation] = useState("");
    const [selectedFacie, setSelectedFacie] = useState([]);

    const objects = state.descriptionObjects.objects;
    const faciesAssociationTypes = state.ontologyInformation.faciesAssociationTypes;
    const preferredLanguages = ["pt-br", "pt"];

    const initialProperties = {
        compLithology: "",
        granulometricMode: "",
        selection: "",
        rounding: "",
        sphericity: "",
        syndepositionalSedimentaryStructure: "",
        postDepositionalSedimentaryStructure: "",
        bioturbation: "",
        topFeatures: "",
        baseFeatures: "",
    }

    useEffect(() => {
        const object = findObj(objects, state.descriptionObjects.selectedObject[0]);
        setSelectedFacie([]);
        setFacies(object?.properties?.facies ?? { data: [], nextKey: 0, update: 0 });
        form.setFieldValue("typeFaciesAssociation", (object?.properties?.typeFaciesAssociation) ?? "")
    }, [state.descriptionObjects.selectedObject])

    useEffect(() => {
        const { data, nextKey, update } = facies;
        if (update) {
            dispatch(
                setObjectProperties(
                    updateObj(
                        state.descriptionObjects.selectedObject[0],
                        state.descriptionObjects.objects,
                        'facies',
                        { data, nextKey },
                    )
                )
            );
        }
    }, [facies]);

    useEffect(() => {
        dispatch(
            setObjectProperties(
                updateObj(
                    state.descriptionObjects.selectedObject[0],
                    state.descriptionObjects.objects,
                    'typeFaciesAssociation',
                    typeFaciesAssociation,
                )
            )
        );
    }, [typeFaciesAssociation])

    useEffect(() => {
        const object = selectedFacie.length > 0 ? findObj(facies.data, selectedFacie[0]) : undefined;
        if (object)
            form.setFieldsValue(object.properties);
        else
            form.setFieldsValue(initialProperties);
    }, [selectedFacie])

    const dispatchProperties = (changedValue) => {
        const [propertyName, propertyValue] = Object.entries(changedValue)[0];
        if (propertyName === "typeFaciesAssociation") {
            setTypeFaciesAssociation(propertyValue);
        }
        else {
            if (selectedFacie.length) {
                setFacies(oldFacies => ({
                    ...oldFacies,
                    data: updateObj(selectedFacie[0], oldFacies.data, propertyName, propertyValue),
                    update: 1
                }));
            }
        }
    }

    const addFacie = () => {
        const newFacie = {
            title: 'Fácies ' + (facies.nextKey + 1),
            key: facies.nextKey,
            properties: initialProperties,
        };

        setFacies((oldFacies) => ({
            nextKey: oldFacies.nextKey + 1,
            data: [...oldFacies.data, newFacie],
            update: 1
        }));
    }

    const deleteFacie = (key) => {
        setFacies(oldFacies => ({
            ...oldFacies,
            data: oldFacies.data.filter(facie => facie.key !== key),
            update: 1
        }));
        setSelectedFacie([]);
    }

    const onSelect = (selectedKeys, resp) => {
        if (resp.selected)
            setSelectedFacie([resp.node.key]);
    }

    const titleRender = (nodeData) => {
        const title = nodeData.title;
        const key = nodeData.key;
        const popConfirmTitle = "Você tem certeza que deseja remover essa fácie?";

        return (
            <Space>
                {title}
                {!viewOnly &&
                    <Popconfirm
                        title={popConfirmTitle}
                        onConfirm={() => deleteFacie(key)}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <a onClick={(e) => { e.preventDefault() }} >
                            <DeleteOutlined style={{ color: "red", display: "inline-flex" }} />
                        </a>
                    </Popconfirm>
                }
            </Space >
        )
    }

    return (
        <Card title="Associação de Fácies">
            <Form
                name="faciesAssoc"
                form={form}
                layout="vertical"
                onValuesChange={debounce(dispatchProperties, 500)}
                initialValues={initialProperties}
            >
                <Row gutter={16}>
                    <Col span={11}>
                        <FloatLabel name="typeFaciesAssociation" label="Tipo">
                            <Select
                                showSearch
                                style={{
                                    width: '100%',
                                }}
                                //options={faciesAssociationTypes}
                                options={faciesAssociationTypes.map((x) => {
                                    return { label:  x.labels[preferredLanguages.find(lang => x.labels[lang])], value: x.labels[preferredLanguages.find(lang => x.labels[lang])] + ""}
                                })}
                                disabled={viewOnly}
                            />
                        </FloatLabel>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={7}>
                        <Card
                            type='inner'
                            size='small'
                            bodyStyle={{ maxHeight: '325px', overflow: 'auto' }}
                            style={{ height: '100%' }}
                            title='Lista de Fácies'
                            extra={!viewOnly && [
                                <Button type='primary' icon={<PlusOutlined />} onClick={addFacie} />
                            ]}
                        >
                            <Tree
                                treeData={facies.data}
                                titleRender={(nodeData) => { return titleRender(nodeData); }}
                                onSelect={onSelect}
                                selectedKeys={selectedFacie}
                            />
                        </Card>
                    </Col>
                    <Col span={17}>
                        <Card
                            type="inner"
                            size='small'
                            style={{ height: '100%' }}
                        >
                            <Row gutter={16}>
                                <Col span={11}>
                                    <FloatLabel name="compLithology" label="Litologia composicional">
                                        <TreeSelect
                                            showSearch
                                            style={{
                                                width: '100%',
                                            }}
                                            dropdownStyle={{
                                                maxHeight: 400,
                                                overflow: 'auto',
                                            }}
                                            treeData={compLithologyData}
                                            treeLine={{ showLeafIcon: false }}
                                            disabled={viewOnly || selectedFacie.length === 0}
                                        />
                                    </FloatLabel>

                                    <Divider type='horizontal' />

                                    <Row gutter={16}>
                                        <Col span={21}>
                                            <FloatLabel name="granulometricMode" label="Moda granulométrica">
                                                <Select style={{ width: "100%" }} disabled={viewOnly || selectedFacie.length === 0}>
                                                    <Option value="Matacão">Matacão</Option>
                                                    <Option value="Bloco">Bloco</Option>
                                                    <Option value="Seixo">Seixo</Option>
                                                    <Option value="Grânulo">Grânulo</Option>
                                                    <Option value="Areia muito grossa">Areia muito grossa</Option>
                                                    <Option value="Areia grossa">Areia grossa</Option>
                                                    <Option value="Areia média">Areia média</Option>
                                                    <Option value="Areia fina">Areia fina</Option>
                                                    <Option value="Areia muito fina">Areia muito fina</Option>
                                                    <Option value="Silte">Silte</Option>
                                                    <Option value="Argila">Argila</Option>
                                                </Select>
                                            </FloatLabel>
                                        </Col>
                                        <Col span={3}>
                                            <Popover
                                                placement="right"
                                                content={<img src={Grainsize} />}
                                                title="Tabela visual da Moda Granulométrica"
                                                trigger="click"
                                            >
                                                <Button
                                                    type="primary"
                                                    className="visual-table"
                                                    icon={<TableOutlined size={25} />}
                                                >
                                                </Button>
                                            </Popover>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={21}>
                                            <FloatLabel name="selection" label="Seleção">
                                                <Select style={{ width: "100%" }} disabled={viewOnly || selectedFacie.length === 0}>
                                                    <Option value="Muito mal selecionado">Muito mal selecionado</Option>
                                                    <Option value="Mal selecionado">Mal selecionado</Option>
                                                    <Option value="Moderadamente selecionado">Moderadamente selecionado</Option>
                                                    <Option value="Bem selecionado">Bem selecionado</Option>
                                                    <Option value="Muito bem selecionado">Muito bem selecionado</Option>
                                                </Select>
                                            </FloatLabel>
                                        </Col>
                                        <Col span={3}>
                                            <Popover
                                                placement="right"
                                                content={<img src={Selection} />}
                                                title="Tabela visual da Seleção"
                                                trigger="click"
                                            >
                                                <Button
                                                    type="primary"
                                                    className="visual-table"
                                                    icon={<TableOutlined size={25} />}
                                                >
                                                </Button>
                                            </Popover>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={21}>
                                            <FloatLabel name="rounding" label="Arredondamento">
                                                <Select style={{ width: "100%" }} disabled={viewOnly || selectedFacie.length === 0}>
                                                    <Option value="Muito Angular">Muito Angular</Option>
                                                    <Option value="Angular">Angular</Option>
                                                    <Option value="Subangular">Subangular</Option>
                                                    <Option value="Subarrendondado">Subarrendondado</Option>
                                                    <Option value="Arrendondado">Arrendondado</Option>
                                                    <Option value="Bem arrendondado">Bem arrendondado</Option>
                                                </Select>
                                            </FloatLabel>
                                        </Col>
                                        <Col span={3}>
                                            <Popover
                                                placement="right"
                                                content={<img src={Roundness} />}
                                                title="Tabela visual do Arredondamento"
                                                trigger="click"
                                            >
                                                <Button
                                                    type="primary"
                                                    className="visual-table"
                                                    icon={<TableOutlined size={25} />}
                                                >
                                                </Button>
                                            </Popover>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={21}>
                                            <FloatLabel name="sphericity" label="Esfericidade">
                                                <Select style={{ width: "100%" }} disabled={viewOnly || selectedFacie.length === 0}>
                                                    <Option value="Alta">Alta</Option>
                                                    <Option value="Média">Média</Option>
                                                    <Option value="Baixa">Baixa</Option>
                                                </Select>
                                            </FloatLabel>
                                        </Col>
                                        <Col span={3}>
                                            <Popover
                                                placement="right"
                                                content={<img src={Roundness} />}
                                                title="Tabela visual da Esfericidade"
                                                trigger="click"
                                            >
                                                <Button
                                                    type="primary"
                                                    className="visual-table"
                                                    icon={<TableOutlined size={25} />}
                                                />
                                            </Popover>
                                        </Col>
                                    </Row>

                                </Col>

                                <Col span={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Divider type='vertical' orientation='center' style={{ height: '100%' }} />
                                </Col>

                                <Col span={11}>
                                    <FloatLabel name="syndepositionalSedimentaryStructure" label="Estrutura sedimentar sindeposicional">
                                        <Select style={{ width: "100%" }} disabled={viewOnly || selectedFacie.length === 0}>
                                            <Option value="Marca onda">Marca onda</Option>
                                            <Option value="Laminacao horizontal">Laminacao horizontal</Option>
                                            <Option value="Laminação plano-paralela">Laminação plano-paralela</Option>
                                            <Option value="Estratificacao cruzada plana">Estratificacao cruzada plana</Option>
                                            <Option value="Estratificacao cruzada acanalada">Estratificacao cruzada acanalada</Option>
                                            <Option value="Estratificação bandeada">Estratificação bandeada</Option>
                                            <Option value="Maciço">Maciço</Option>
                                            <Option value="Gradação normal">Gradação normal</Option>
                                            <Option value="Gradação inversa">Gradação inversa</Option>
                                        </Select>
                                    </FloatLabel>

                                    <FloatLabel name="postDepositionalSedimentaryStructure" label="Estrutura sedimentar pós-deposicional">
                                        <Select style={{ width: "100%" }} disabled={viewOnly || selectedFacie.length === 0}>
                                            <Option value="Fluidização">Fluidização</Option>
                                            <Option value="Contorção">Contorção</Option>
                                            <Option value="Dobra">Dobra</Option>
                                            <Option value="Erosão">Erosão</Option>
                                            <Option value="Escorregamento">Escorregamento</Option>
                                            <Option value="Colapso">Colapso</Option>
                                            <Option value="Fraturamento">Fraturamento</Option>
                                            <Option value="Falhamento">Falhamento</Option>
                                            <Option value="Recristalizacao">Recristalizacao</Option>
                                            <Option value="Compactação">Compactação</Option>
                                        </Select>
                                    </FloatLabel>

                                    <Divider type='horizontal' />

                                    <FloatLabel name="topFeatures" label="Feições topo">
                                        <Select style={{ width: "100%" }} disabled={viewOnly || selectedFacie.length === 0}>
                                            <Option value="Valor 1">Valor 1</Option>
                                            <Option value="Valor 2">Valor 2</Option>
                                        </Select>
                                    </FloatLabel>

                                    <FloatLabel name="baseFeatures" label="Feições base">
                                        <Select style={{ width: "100%" }} disabled={viewOnly || selectedFacie.length === 0}>
                                            <Option value="Valor 1">Valor 1</Option>
                                            <Option value="Valor 2">Valor 2</Option>
                                        </Select>
                                    </FloatLabel>

                                    <Divider type='horizontal' />

                                    <FloatLabel name="bioturbation" label="Bioturbação">
                                        <Select style={{ width: "100%" }} disabled={viewOnly || selectedFacie.length === 0}>
                                            <Option value="Abundante">Abundante</Option>
                                            <Option value="Frequente">Frequente</Option>
                                            <Option value="Ocasional">Ocasional</Option>
                                            <Option value="Raro">Raro</Option>
                                            <Option value="Traço">Traço</Option>
                                        </Select>
                                    </FloatLabel>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

const ObjectProperties = (props) => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const [form] = Form.useForm();
    const { viewOnly } = props;
    const objects = state.descriptionObjects.objects;

    useEffect(() => {
        const properties = findObj(objects, state.descriptionObjects.selectedObject[0])?.properties;
        form.setFieldsValue({
            area: (properties && properties.hasOwnProperty('area')) ? properties.area : "",
            heightMax: (properties && properties.hasOwnProperty('heightMax')) ? properties.heightMax : "",
            heightMean: (properties && properties.hasOwnProperty('heightMean')) ? properties.heightMean : "",
            heightMedian: (properties && properties.hasOwnProperty('heightMedian')) ? properties.heightMedian : "",
            heightMin: (properties && properties.hasOwnProperty('heightMin')) ? properties.heightMin : "",
            heightMode: (properties && properties.hasOwnProperty('heightMode')) ? properties.heightMode : "",
            widthMax: (properties && properties.hasOwnProperty('widthMax')) ? properties.widthMax : "",
            widthMean: (properties && properties.hasOwnProperty('widthMean')) ? properties.widthMean : "",
            widthMedian: (properties && properties.hasOwnProperty('widthMedian')) ? properties.widthMedian : "",
            widthMin: (properties && properties.hasOwnProperty('widthMin')) ? properties.widthMin : "",
            widthMode: (properties && properties.hasOwnProperty('widthMode')) ? properties.widthMode : "",
        })
    }, [state.descriptionObjects.selectedObject])

    const dispatchProperties = (changedValue) => {
        const [name, value] = Object.entries(changedValue)[0]
        dispatch(
            setObjectProperties(
                updateObj(
                    state.descriptionObjects.selectedObject[0],
                    state.descriptionObjects.objects,
                    name,
                    value,
                )
            )
        );
    }

    return (
        <Card title="Propriedades do Objeto" style={{ height: '100%' }}>
            <Form
                name="dimension"
                form={form}
                layout="vertical"
                onValuesChange={dispatchProperties}
                disabled={viewOnly}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <FloatLabel label="Altura Média" name="heightMean">
                            <InputNumber style={{ width: '100%' }} precision={2} />
                        </FloatLabel>
                        <FloatLabel label="Altura Mediana" name="heightMedian">
                            <InputNumber style={{ width: '100%' }} precision={2} />
                        </FloatLabel>
                        <FloatLabel label="Altura Modal" name="heightMode">
                            <InputNumber style={{ width: '100%' }} precision={2} />
                        </FloatLabel>
                        <FloatLabel label="Altura Máxima" name="heightMax">
                            <InputNumber style={{ width: '100%' }} precision={2} />
                        </FloatLabel>
                        <FloatLabel label="Altura Mínima" name="heightMin">
                            <InputNumber style={{ width: '100%' }} precision={2} />
                        </FloatLabel>
                    </Col>
                    <Col span={12}>
                        <FloatLabel label="Largura Média" name="widthMean">
                            <InputNumber style={{ width: '100%' }} precision={2} />
                        </FloatLabel>
                        <FloatLabel label="Largura Mediana" name="widthMedian">
                            <InputNumber style={{ width: '100%' }} precision={2} />
                        </FloatLabel>
                        <FloatLabel label="Largura Modal" name="widthMode">
                            <InputNumber style={{ width: '100%' }} precision={2} />
                        </FloatLabel>
                        <FloatLabel label="Largura Máxima" name="widthMax">
                            <InputNumber style={{ width: '100%' }} precision={2} />
                        </FloatLabel>
                        <FloatLabel label="Largura Mínima" name="widthMin">
                            <InputNumber style={{ width: '100%' }} precision={2} />
                        </FloatLabel>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

const updateObj = (key, data, propertyName, propertyValue) => {
    const updateData = (data, key) => data.map(e => {
        if (e.children)
            e.children = updateData(e.children, key);
        if (e.key === key) {
            return {
                ...e, properties: {
                    ...e.properties,
                    [propertyName]: propertyValue,
                }
            };
        }
        return e;
    })

    const newArray = updateData(data, key);
    return newArray;
}

const findObj = (data, key) => {
    let result = null;
    const findData = (data, key) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i].children)
                findData(data[i].children, key);
            if (data[i].key === key) {
                result = data[i];
            }
        }

        return result;
    }

    return findData(data, key);
}
