import React from 'react';
import { Breadcrumb, BreadcrumbItemDesactivated, BreadcrumbItemActivated } from '../../Breadcrumb';
import MapInformation from '../../MapInformation';
import Description from '../../Description';

function ReadOccurrence() {
    return (
        <div class="container-fluid d-grid gap-2">

            <Breadcrumb>
                <BreadcrumbItemDesactivated href="/occurrence">Ocorrência</BreadcrumbItemDesactivated>
                <BreadcrumbItemActivated>Informações</BreadcrumbItemActivated>
            </Breadcrumb>


            <div class="row mb-2">
                <div class="col-9">
                    <h2>Informações da Ocorrência</h2>
                </div>
                <div class="col-3">
                    <button type="button" class="btn btn-danger">Remover Ocorrência</button>
                </div>
            </div>

            <MapInformation
                localX="-29.9189"
                localY="-51.1781"

                cityName="Canoas"
                stateName="Rio Grande do Sul"
                coutryName="Brasil"

                occurenceName="Ocorrencia do bairro niterói"
                occurenceAuthor="Fulano de tal"
                type="Afloramento"
                watershed="Delta do Jacuí"

                litho="Grupo"
                geocronologyBegin="Cenozóico"
                geocronologyEnd="unknown"
                depEnv="opção 3"
                
                bodyStyle=""
            />

            <Description />
        </div>

    );
}


export default ReadOccurrence;