import { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { store } from '../Store'
import { setDescriptionObjectsAction } from '../Store/actions';
import './index.css';

const Window = ({ ctm }) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isActive, setIsActive] = useState(true);
  const draggableWindow = useRef(null);

  // const svgDoc = useMemo(() => {
  //   const doc = document.querySelector("object");
  //   return doc.contentDocument;
  // }, []);

  useEffect(() => {
    const handleMouseUp = (e) => {
      setIsDragging(false);
    };
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  useEffect(() => {
    setIsActive(true);
  }, [isDragging, ctm])

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    const doc = document.querySelector("object");
    const svgDoc = doc.contentDocument;

    if (!svgDoc)
      return;

    const { clientHeight, clientWidth } = svgDoc.children[0];
    const { offsetHeight, offsetWidth } = draggableWindow.current;
    if (isDragging) {
      setPosition({
        top: Math.max(0, Math.min(position.top + e.movementY, clientHeight - offsetHeight)),
        left: Math.max(0, Math.min(position.left + e.movementX, clientWidth - offsetWidth)),
      });
    }
  };

  const handleOnDoubleClick = (e) => {
    dispatch(setDescriptionObjectsAction({
      ...state.descriptionObjects,
      box: { ...state.descriptionObjects.box, method: 'window', update: state.descriptionObjects.box.update + 1 }
    }));
    setIsActive(false);
  }

  return (
    <div
      className={`window ${isActive ? 'active' : ''}`}
      id="window"
      style={{ top: position.top, left: position.left, width: 100, height: 100, maxWidth: '100%', maxHeight: '100%' }}
      ref={draggableWindow}
      onDoubleClick={handleOnDoubleClick}
    >
      <div className="window-header" onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} />
    </div>
  );
};

export default Window;