import { React, useEffect, useState } from 'react';
import DescriptionTable from './DescriptionTable/DescriptionTable';
import { Space, Layout, Breadcrumb, Typography } from 'antd'
import { useAxiosGet } from '../../hooks/useAxios';
import DescriptionsDataFilter from './DescriptionsDataFilter';
import ArchitecturalTypeColorsDrawer from '../ArchitecturalTypeColorsDrawer';
import { Map, DescriptionsLocationMarkers } from '../Maps';
import VisualAnalysisContainer from '../VisualAnalysisContainer';

const { Content } = Layout;
const { Link } = Typography;


function Description() {

    const { data: descriptionsData, error: descriptionsDataError, loaded: descriptionsDataLoaded } = useAxiosGet("http://api-geoserver.inf.ufrgs.br/description/query");
    const { data: descriptionFieldsData, error: descriptionFieldsDataError, loaded: descriptionFieldsDataLoaded } = useAxiosGet("http://api-geoserver.inf.ufrgs.br/description/data", sessionStorage);

    const [descriptionsDataFiltered, setDescriptionsDataFiltered] = useState(false);

    useEffect(()=>{
        setDescriptionsDataFiltered(descriptionsData)
    }, [descriptionsData])

    return (
        <>
            <Content style={{ padding: '0 100px 50px 100px' }}>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">
                            <Link>Home </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Busca de Análogos
                        </Breadcrumb.Item>
                    </Breadcrumb>

                    <ArchitecturalTypeColorsDrawer/>

                    <DescriptionsDataFilter
                        setDescriptionsDataFiltered={setDescriptionsDataFiltered}
                        descriptionsData={descriptionsData}
                        descriptionFieldsData={descriptionFieldsData}
                        descriptionFieldsDataLoaded={descriptionFieldsDataLoaded}
                    />

                    <Map
                        center={[0, 0]}
                        zoom="3"
                        styleHeight='55vh'
                    >
                        {descriptionsDataFiltered && <DescriptionsLocationMarkers data={descriptionsDataFiltered} />}
                    </Map>

                    {descriptionsDataFiltered && <VisualAnalysisContainer data={descriptionsDataFiltered} />}

                    <DescriptionTable descriptionsDataFiltered={descriptionsDataFiltered} descriptionsDataLoaded={descriptionsDataLoaded} descriptionFieldsData={descriptionFieldsData}/>
                </Space>
            </Content>
        </>
    );
}


export default Description;