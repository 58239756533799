import axios from "axios";

const API_URL = "http://api-geoserver.inf.ufrgs.br/auth/login";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL, {
        username,
        password
      })
      .then(response => {
        if (response.data[0].access_token) {
          localStorage.setItem("user", JSON.stringify(response.data[0]));
        }
        return response.data;
      });
  }
}

export default new AuthService();