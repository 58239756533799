import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as CgIcons from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import { ProfilebarData } from './ProfilebarData';
import './Sidebar.css';
import { IconContext } from 'react-icons';


function Sidebar() {
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    const [profilebar, setProfilebar] = useState(false);
    const showProfilebar = () => setProfilebar(!profilebar);
    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <div class="navbar" className="navbar">
                    <Link to="#" className='menu-bars'>
                        <FaIcons.FaBars onClick={showSidebar} />
                    </Link>
                    <Link to="#" className='menu-bars-prof'>
                        <CgIcons.CgProfile onClick={showProfilebar} />
                    </Link>
                </div>


                <nav className={profilebar ? 'nav-menu-profilebar active' : 'nav-menu-profilebar'}>
                    <ul className='nav-menu-items' onClick={showProfilebar}>
                        <li className="navbar-toggle">
                            <Link to="#" className='menu-bars'>
                                <AiIcons.AiOutlineClose />
                            </Link>
                        </li>
                        {ProfilebarData.map((item, index) => {
                            return (
                                <li key={index} className={item.cName}>
                                    <Link to={item.path}>
                                        {item.icon}
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
                <nav className={sidebar ? 'nav-menu-sidebar active' : 'nav-menu-sidebar'}>
                    <ul className='nav-menu-items' onClick={showSidebar}>
                        <li className="navbar-toggle">
                            <Link to="#" className='menu-bars'>
                                <AiIcons.AiOutlineClose />
                            </Link>
                        </li>
                        {SidebarData.map((item, index) => {
                            return (
                                <li key={index} className={item.cName}>
                                    <Link to={item.path}>
                                        {item.icon}
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </IconContext.Provider>
        </>
    );
}

export default Sidebar;