import { useState, useEffect } from "react";
import axios from "axios";

export const useAxiosGet = (url, storage) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
      let storedData = storage?.getItem(url);

      if (storedData) {
        setData(JSON.parse(storedData));
        setLoaded(true);
      } else {
        axios
          .get(url)
          .then((response) => {
            setData(response.data);
            (storage && storage.setItem(url, JSON.stringify(response.data)));
          })
          .catch((error) => setError(error.message))
          .finally(() => setLoaded(true));
      }
    }, []);
    return { data, error, loaded };
  };

export const useAxiosPost = (url, payload) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    axios
      .post(url, payload)
      .then((response) => setData(response.data))
      .catch((error) => setError(error.message))
      .finally(() => setLoaded(true));
  }, []);
  return { data, error, loaded };
};
