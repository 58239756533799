import { Form, Button, Space, List, Input, Popconfirm, Divider, Row, Col } from 'antd'
import { PlusOutlined, LinkOutlined, DeleteOutlined, FileOutlined } from '@ant-design/icons';
import './index.css';

function InputMultiplePrint(props) {
    const { title, id, viewOnly } = props
    const prefixIcon = (id === "refLink") ? <LinkOutlined /> : <FileOutlined />;
    const isValidHttpUrl = (string) => {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    };

    return (
        <Form.List name={id}>
            {(fields, { add, remove }) => {
                return (
                    <div>
                        {fields.map((field, index) => (
                            <div key={field.key}>
                                <Row gutter={16}>
                                    <Col span={viewOnly ? 24 : 23}>
                                        <Form.Item
                                            name={[index, "name"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Por favor, insira um valor no campo"
                                                },
                                                {
                                                    validator(_, value) {
                                                        if (!value || id !== "refLink" || (id === "refLink" && isValidHttpUrl(value))) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("Deve ser um link!"));
                                                    }
                                                },
                                            ]}
                                        >
                                            <Input prefix={prefixIcon} placeholder={title + " " + (index + 1)} />
                                        </Form.Item>
                                    </Col>
                                    {!viewOnly &&
                                        <Col span={1}>
                                            <Popconfirm placement="top" title="Você tem certeza que deseja remover?" onConfirm={() => remove(field.name)} okText="Sim" cancelText="Não">
                                                <DeleteOutlined className="delete-button" />
                                            </Popconfirm>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        ))}
                        {!viewOnly &&
                            <Form.Item>
                                <Button
                                    block
                                    type="dashed"
                                    onClick={() => add()}
                                >
                                    <PlusOutlined style={{ display: "inline-flex" }} /> {title}
                                </Button>
                            </Form.Item>
                        }
                    </div>
                );
            }}
        </Form.List>
    )
}

export default InputMultiplePrint;