export const compLithologyData = [
    {
        title: 'Siliciclástica',
        value: 'Siliciclástica ',
        children: [
            {
                title: 'Conglomerado',
                value: 'Conglomerado',
            },
            {
                title: 'Arenito',
                value: 'Arenito',
            },
            {
                title: 'Siltito ',
                value: 'Siltito ',
            },
            {
                title: 'Argilito ',
                value: 'Argilito ',
            },
            {
                title: 'Heterolito ',
                value: 'Heterolito ',
            },
            {
                title: 'Diamictito',
                value: 'Diamictito',
            },
        ]
    },
    {
        title: 'Carbonatica',
        value: 'Carbonatica ',
        children: [
            {
                title: 'Calcario',
                value: 'Calcario',
            },
            {
                title: 'Dolomito',
                value: 'Dolomito',
            }
        ]
    },
    {
        title: 'Evaporito',
        value: 'Evaporito',
    },
    {
        title: 'Quimica',
        value: 'Quimica ',
        children: [
            {
                title: 'Silicosa',
                value: 'Silicosa',
            },
            {
                title: 'Ferrifera',
                value: 'Ferrifera',
            },
            {
                title: 'Fosfática',
                value: 'Fosfática',
            }
        ]
    },
    {
        title: 'Metamorfica',
        value: 'Metamorfica ',
        children: [
            {
                title: 'Cataclasito (inclui brechas)',
                value: 'Cataclasito',
            },
            {
                title: 'Xisto',
                value: 'Xisto',
            },
            {
                title: 'Gnaisse',
                value: 'Gnaisse',
            },
            {
                title: 'Granulare',
                value: 'Granulare',
            }
        ]
    },
    {
        title: 'Ignea',
        value: 'Ignea ',
        children: [
            {
                title: 'Vulcanica extrusiva',
                value: 'Vulcanica extrusiva',
            },
            {
                title: 'Vulcanica intrusiva',
                value: 'Vulcanica intrusiva',
            },
            {
                title: 'Plutonica/ Hipoabissal',
                value: 'Plutonica/ Hipoabissal',
            },
            {
                title: 'Hidrotermal',
                value: 'Hidrotermal',
            }
        ]
    }
];