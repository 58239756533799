import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map, LocationMarker } from '../Maps';
import CardColumn from '../Card'
import InlineEdit from "../InlineEdit";


function MapInformation(props) {
    var localX = props.localX;
    var localY = props.localY;

    var cityName = props.cityName;
    var stateName = props.stateName;
    var coutryName = props.coutryName

    var occurenceAuthor = props.occurenceAuthor
    var type = props.type
    var watershed = props.watershed

    var styleHeight = props.styleHeight
    var styleWidth = props.styleWidth

    var bodyStyle = props.bodyStyle

    return (
        <div class="row">

            <div class="col-4">
                <CardColumn bodyStyle={bodyStyle}>
                    <div class="row">
                        <p>Autor: {occurenceAuthor}</p>
                    </div>
                    <div class="row">
                        <p>Coordenada geográfica/UTM/LatiLong: {localX}, {localY}</p>
                    </div>
                    <div class="row">
                        <p>Cidade/Região: {cityName}</p>
                    </div>
                    <div class="row">
                        <p>Estado: {stateName}</p>
                    </div>
                    <div class="row">
                        <p>País: {coutryName}</p>
                    </div>
                    <div class="row">
                        <p>Tipo: {type}</p>
                    </div>
                    <div class="row">
                        <p>Bacia: {watershed}</p>
                    </div>

                </CardColumn>
            </div>
            <div class="col-8">
                <div class="row">
                    <Map
                        center={[localX, localY]}
                        zoom="3"
                        styleHeight='70vh'
                        styleWidth={styleWidth}
                    >
                        <LocationMarker position={[localX, localY]}/>
                    </Map>
                </div>
            </div>
        </div>
    );
}

MapInformation.defaultProps = {
    styleHeight: '75vh',
    styleWidth: '',
    bodyStyle: '',
};


export default MapInformation;