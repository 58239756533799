import React from 'react';
import PropTypes from 'prop-types';

export function BreadcrumbItemDesactivated(props) {
    return (
        <li class="breadcrumb-item"><a href={props.href}>{props.children}</a></li>
    );
}

BreadcrumbItemDesactivated.propTypes = {
    href: PropTypes.string,
    children: PropTypes.node,
};

export function BreadcrumbItemActivated(props) {
    return (
        <li class="breadcrumb-item active" aria-current="page">{props.children}</li>
    );
}

BreadcrumbItemActivated.propTypes = {
    children: PropTypes.node,
};

export function Breadcrumb(props) {
    return (
        <div class="row">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    {props.children}
                </ol>
            </nav>
        </div>
    );
}

Breadcrumb.propTypes = {
    children: PropTypes.node,
};
