import { useEffect, useState, cloneElement } from "react";
import { createPortal } from 'react-dom';

function copyStyles(sourceDoc, targetDoc) {
  Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
    if (styleSheet.cssRules) { // for <style> elements
      const newStyleEl = sourceDoc.createElement('style');

      Array.from(styleSheet.cssRules).forEach(cssRule => {
        // write the text of each rule into the body of the style element
        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
      });

      targetDoc.head.appendChild(newStyleEl);
    } else if (styleSheet.href) { // for <link> elements loading CSS from a URL
      const newLinkEl = sourceDoc.createElement('link');

      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  });
}

function WindowPortal(props) {
    const { forceRefresh, setPortalOpen } = props;
    const [externalWindow, setExternalWindow] = useState();
    const [containerEl, setContainerEl] = useState();

    const debounce = (func) => {
      let timer;
      return (e) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(func, 100, e);
      };
    };
    
    useEffect(() => {
        const windowPortal = window.open('', '', 'width=600,height=400,left=200,top=200');
        setExternalWindow(windowPortal);
        windowPortal.addEventListener('resize', debounce(() => forceRefresh()));

        const heartbeat = setInterval(() => {
          // console.log('heartbeat');
          if (windowPortal.closed) {
            clearInterval(heartbeat);
            setPortalOpen(false);
          }
        }, 100);

        return () => {
          windowPortal.removeEventListener('resize', debounce(() => forceRefresh()));
          windowPortal.close();
          clearInterval(heartbeat);
        };
    }, []);

    useEffect(() => {
        if (externalWindow) {
            copyStyles(document, externalWindow.document);
            const newContainerEl = externalWindow.document.createElement('div');
            setContainerEl(newContainerEl);
            externalWindow.document.body.appendChild(newContainerEl);
        }
    }, [externalWindow]);

    return containerEl ? createPortal(cloneElement(props.children, { targetWindow: externalWindow }), containerEl) : null;
}

export default WindowPortal;
